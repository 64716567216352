const DETRCodes = [
  {
    id: 12,
    text: "NETWORK RAIL",
    xmin: -83982.94519240677,
    ymin: 72747.56239049905,
    xmax: 787399.1772583753,
    ymax: 1078658.8546231566,
    zoomLevel: 6,
  },
  {
    id: 15,
    text: "HIGHWAYS AGENCY",
    xmin: -83982.94519240677,
    ymin: 72747.56239049905,
    xmax: 787399.1772583753,
    ymax: 1078658.8546231566,
    zoomLevel: 7,
  },
  {
    id: 16,
    text: "WELSH GOVERNMENT",
    xmin: 115670.57268842091,
    ymin: 139247.77699858515,
    xmax: 494798.23298279627,
    ymax: 413503.834485726,
    zoomLevel: 9,
  },
  {
    id: 19,
    text: "SCOTTISH OFFICE",
    xmin: -83982.94519240677,
    ymin: 530758.235875156,
    xmax: 674272.3753963439,
    ymax: 1078658.8546231566,
    zoomLevel: 7,
  },
  {
    id: 20,
    text: "TRANSPORT FOR LONDON (TFL)",
    xmin: 483931.80396800535,
    ymin: 139807.99830547522,
    xmax: 578713.7190415992,
    ymax: 207760.51645097922,
    zoomLevel: 11,
  },
  {
    id: 22,
    text: "NORTHERN IRELAND EXECUTIVE",
    xmin: -114557.75650647002,
    ymin: 387515.24486877315,
    xmax: 264569.9037879053,
    ymax: 661771.302355914,
    zoomLevel: 9,
  },
  {
    id: 114,
    text: "BATH AND NORTH EAST SOMERSET COUNCIL",
    xmin: 354211.428709322,
    ymin: 151716.56723760092,
    xmax: 377906.9074777204,
    ymax: 168857.57083054725,
    zoomLevel: 13,
  },
  {
    id: 116,
    text: "BRISTOL CITY COUNCIL",
    xmin: 350427.79580920713,
    ymin: 166488.02295370708,
    xmax: 374123.27457760554,
    ymax: 183629.02654665336,
    zoomLevel: 13,
  },
  {
    id: 119,
    text: "SOUTH GLOUCESTERSHIRE COUNCIL",
    xmin: 345688.70005552756,
    ymin: 169574.1679707202,
    xmax: 393079.6575923245,
    ymax: 203856.17515661282,
    zoomLevel: 12,
  },
  {
    id: 121,
    text: "NORTH SOMERSET COUNCIL",
    xmin: 319126.83272643614,
    ymin: 150273.81832871833,
    xmax: 366517.79026323307,
    ymax: 184555.82551461094,
    zoomLevel: 12,
  },
  {
    id: 230,
    text: "LUTON BOROUGH COUNCIL",
    xmin: 502088.4145554951,
    ymin: 218173.78601727626,
    xmax: 513936.15393969434,
    ymax: 226744.28781374943,
    zoomLevel: 14,
  },
  {
    id: 235,
    text: "BEDFORD BOROUGH COUNCIL",
    xmin: 500234.81661958067,
    ymin: 245318.4856870426,
    xmax: 512082.55600377993,
    ymax: 253888.98748351575,
    zoomLevel: 14,
  },
  {
    id: 240,
    text: "CENTRAL BEDFORDSHIRE COUNCIL",
    xmin: 487307.4042108537,
    ymin: 219764.63166846125,
    xmax: 534698.3617476505,
    ymax: 254046.63885435386,
    zoomLevel: 12,
  },
  {
    id: 335,
    text: "BRACKNELL FOREST COUNCIL",
    xmin: 475230.3537418002,
    ymin: 159159.62286686793,
    xmax: 498925.8325101986,
    ymax: 176300.6264598142,
    zoomLevel: 13,
  },
  {
    id: 340,
    text: "WEST BERKSHIRE COUNCIL",
    xmin: 425355.19278573664,
    ymin: 156532.10001956578,
    xmax: 472746.15032253356,
    ymax: 190814.1072054584,
    zoomLevel: 12,
  },
  {
    id: 345,
    text: "READING BOROUGH COUNCIL",
    xmin: 457917.36683521315,
    ymin: 163717.18067837053,
    xmax: 481612.84560361155,
    ymax: 180858.1842713168,
    zoomLevel: 13,
  },
  {
    id: 350,
    text: "SLOUGH BOROUGH COUNCIL",
    xmin: 486753.2357557884,
    ymin: 171007.36225106707,
    xmax: 510448.7145241868,
    ymax: 188148.36584401334,
    zoomLevel: 13,
  },
  {
    id: 355,
    text: "ROYAL BOROUGH OF WINDSOR AND MAIDENHEAD",
    xmin: 468714.09708049195,
    ymin: 158079.9498423402,
    xmax: 516105.0546172889,
    ymax: 192361.9570282328,
    zoomLevel: 12,
  },
  {
    id: 360,
    text: "WOKINGHAM BOROUGH COUNCIL",
    xmin: 474838.6139718401,
    ymin: 163588.1931931393,
    xmax: 486686.3533560394,
    ymax: 172158.69498961247,
    zoomLevel: 14,
  },
  {
    id: 435,
    text: "MILTON KEYNES CITY COUNCIL",
    xmin: 473716.8587856627,
    ymin: 229804.92755863152,
    xmax: 497412.3375540611,
    ymax: 246945.9311515778,
    zoomLevel: 13,
  },
  {
    id: 440,
    text: "BUCKINGHAMSHIRE COUNCIL",
    xmin: 437867.89251992415,
    ymin: 174263.87188092998,
    xmax: 532649.8075935179,
    ymax: 242827.8862527152,
    zoomLevel: 11,
  },
  {
    id: 505,
    text: "CAMBRIDGE CITY COUNCIL",
    xmin: 539787.1151096442,
    ymin: 253591.17529811885,
    xmax: 551634.8544938435,
    ymax: 262161.677094592,
    zoomLevel: 14,
  },
  {
    id: 510,
    text: "EAST CAMBRIDGESHIRE DISTRICT COUNCIL",
    xmin: 511489.7191898598,
    ymin: 239820.2237909218,
    xmax: 606271.6342634537,
    ymax: 308384.238162707,
    zoomLevel: 11,
  },
  {
    id: 515,
    text: "FENLAND DISTRICT COUNCIL",
    xmin: 495437.94324997836,
    ymin: 264700.4764977402,
    xmax: 590219.8583235722,
    ymax: 333264.4908695254,
    zoomLevel: 11,
  },
  {
    id: 520,
    text: "HUNTINGDONSHIRE DISTRICT COUNCIL",
    xmin: 476787.30834840145,
    ymin: 245820.53051130642,
    xmax: 571569.2234219953,
    ymax: 314384.54488309164,
    zoomLevel: 11,
  },
  {
    id: 530,
    text: "SOUTH CAMBRIDGESHIRE DISTRICT COUNCIL",
    xmin: 498724.735466241,
    ymin: 225335.40693088455,
    xmax: 593506.6505398349,
    ymax: 293899.4213026698,
    zoomLevel: 11,
  },
  {
    id: 535,
    text: "CAMBRIDGESHIRE COUNTY COUNCIL",
    xmin: 453550.45174971246,
    ymin: 206493.67945859372,
    xmax: 643114.2818969002,
    ymax: 343621.70820216415,
    zoomLevel: 10,
  },
  {
    id: 540,
    text: "PETERBOROUGH CITY COUNCIL",
    xmin: 496240.5320469724,
    ymin: 284975.3982503776,
    xmax: 543631.4895837693,
    ymax: 319257.4054362703,
    zoomLevel: 12,
  },
  {
    id: 650,
    text: "HALTON BOROUGH COUNCIL",
    xmin: 346108.65386639006,
    ymin: 461463.71924653545,
    xmax: 357956.3932505893,
    ymax: 470034.2210430086,
    zoomLevel: 14,
  },
  {
    id: 655,
    text: "WARRINGTON BOROUGH COUNCIL",
    xmin: 350064.2700801467,
    ymin: 380321.0364075743,
    xmax: 373759.74884854513,
    ymax: 397462.04000052065,
    zoomLevel: 13,
  },
  {
    id: 660,
    text: "CHESHIRE EAST COUNCIL",
    xmin: 335407.4699064679,
    ymin: 337181.88856913947,
    xmax: 430189.38498006173,
    ymax: 405745.9029409247,
    zoomLevel: 11,
  },
  {
    id: 665,
    text: "CHESHIRE WEST AND CHESTER COUNCIL",
    xmin: 298259.0741598824,
    ymin: 325143.0566142268,
    xmax: 393040.9892334762,
    ymax: 393707.070986012,
    zoomLevel: 11,
  },
  {
    id: 724,
    text: "HARTLEPOOL BOROUGH COUNCIL",
    xmin: 438979.6432328551,
    ymin: 522799.6571311059,
    xmax: 462675.1220012535,
    ymax: 539940.6607240522,
    zoomLevel: 13,
  },
  {
    id: 728,
    text: "REDCAR AND CLEVELAND BOROUGH COUNCIL",
    xmin: 437278.91935351025,
    ymin: 501808.13823829475,
    xmax: 484669.8768903072,
    ymax: 536090.1454241873,
    zoomLevel: 12,
  },
  {
    id: 734,
    text: "MIDDLESBROUGH BOROUGH COUNCIL",
    xmin: 444120.0333850317,
    ymin: 513956.8484213665,
    xmax: 455967.77276923094,
    ymax: 522527.35021783964,
    zoomLevel: 14,
  },
  {
    id: 738,
    text: "STOCKTON-ON-TEES BOROUGH COUNCIL",
    xmin: 435864.8343302349,
    ymin: 509920.0178650566,
    xmax: 459560.3130986333,
    ymax: 527061.0214580029,
    zoomLevel: 13,
  },
  {
    id: 835,
    text: "COUNCIL OF THE ISLES OF SCILLY",
    xmin: 76654.27604524886,
    ymin: 2293.3845041992918,
    xmax: 100349.75481364732,
    ymax: 19434.388097145595,
    zoomLevel: 13,
  },
  {
    id: 840,
    text: "CORNWALL COUNCIL",
    xmin: 122401.837473915,
    ymin: -1442.4752532377315,
    xmax: 311965.66762110265,
    ymax: 135685.5534903327,
    zoomLevel: 10,
  },
  {
    id: 935,
    text: "WESTMORLAND AND FURNESS COUNCIL",
    xmin: 262143.777466612,
    ymin: 443037.50391911046,
    xmax: 451707.6076137997,
    ymax: 580165.5326626808,
    zoomLevel: 10,
  },
  {
    id: 940,
    text: "CUMBERLAND COUNCIL",
    xmin: 252512.71190268197,
    ymin: 464592.7458955245,
    xmax: 442076.54204986966,
    ymax: 601720.774639095,
    zoomLevel: 10,
  },
  {
    id: 1005,
    text: "AMBER VALLEY DISTRICT BOROUGH COUNCIL",
    xmin: 411886.1423893447,
    ymin: 330889.56617137615,
    xmax: 459277.0999261416,
    ymax: 365171.5733572688,
    zoomLevel: 12,
  },
  {
    id: 1010,
    text: "BOLSOVER DISTRICT COUNCIL",
    xmin: 423886.7558301145,
    ymin: 349272.67147395643,
    xmax: 471277.7133669114,
    ymax: 383554.6786598491,
    zoomLevel: 12,
  },
  {
    id: 1015,
    text: "CHESTERFIELD BOROUGH COUNCIL",
    xmin: 433909.5611640056,
    ymin: 368319.8234597637,
    xmax: 445757.30054820486,
    ymax: 376890.3252562369,
    zoomLevel: 14,
  },
  {
    id: 1025,
    text: "EREWASH BOROUGH COUNCIL",
    xmin: 430489.0041482445,
    ymin: 327631.43784072116,
    xmax: 454184.4829166429,
    ymax: 344772.4414336675,
    zoomLevel: 13,
  },
  {
    id: 1030,
    text: "HIGH PEAK BOROUGH COUNCIL",
    xmin: 388486.8571055514,
    ymin: 367636.667519466,
    xmax: 435877.8146423483,
    ymax: 401918.6747053587,
    zoomLevel: 12,
  },
  {
    id: 1035,
    text: "NORTH EAST DERBYSHIRE DISTRICT COUNCIL",
    xmin: 414513.6652366468,
    ymin: 353495.81728671247,
    xmax: 461904.6227734437,
    ymax: 387777.824472605,
    zoomLevel: 12,
  },
  {
    id: 1040,
    text: "SOUTH DERBYSHIRE DISTRICT COUNCIL",
    xmin: 409965.6620536803,
    ymin: 307748.255858046,
    xmax: 457356.6195904772,
    ymax: 342030.26304393867,
    zoomLevel: 12,
  },
  {
    id: 1045,
    text: "DERBYSHIRE DALES DISTRICT COUNCIL",
    xmin: 375874.747438501,
    ymin: 325615.411219701,
    xmax: 470656.66251209483,
    ymax: 394179.4255914862,
    zoomLevel: 11,
  },
  {
    id: 1050,
    text: "DERBYSHIRE COUNTY COUNCIL",
    xmin: 328330.9158451339,
    ymin: 302722.5212482964,
    xmax: 517894.7459923216,
    ymax: 439850.54999186686,
    zoomLevel: 10,
  },
  {
    id: 1055,
    text: "DERBY CITY COUNCIL",
    xmin: 423342.1420035837,
    ymin: 325395.6547633813,
    xmax: 447037.6207719821,
    ymax: 342536.6583563276,
    zoomLevel: 13,
  },
  {
    id: 1105,
    text: "EAST DEVON DISTRICT COUNCIL",
    xmin: 270486.0032023871,
    ymin: 63083.02492187641,
    xmax: 365267.9182759809,
    ymax: 131647.03929366163,
    zoomLevel: 11,
  },
  {
    id: 1110,
    text: "EXETER CITY COUNCIL",
    xmin: 287970.9734226167,
    ymin: 88378.90396999544,
    xmax: 299818.712806816,
    ymax: 96949.40576646858,
    zoomLevel: 14,
  },
  {
    id: 1115,
    text: "NORTH DEVON DISTRICT COUNCIL",
    xmin: 223458.12154994445,
    ymin: 95836.29154206596,
    xmax: 318240.0366235383,
    ymax: 164400.3059138512,
    zoomLevel: 11,
  },
  {
    id: 1125,
    text: "SOUTH HAMS DISTRICT COUNCIL",
    xmin: 247803.31505876663,
    ymin: 34132.50045887347,
    xmax: 295194.27259556355,
    ymax: 68414.50764476607,
    zoomLevel: 12,
  },
  {
    id: 1130,
    text: "TEIGNBRIDGE DISTRICT COUNCIL",
    xmin: 259383.52484396752,
    ymin: 62452.41943852372,
    xmax: 306774.48238076444,
    ymax: 96734.42662441633,
    zoomLevel: 12,
  },
  {
    id: 1135,
    text: "MID DEVON DISTRICT COUNCIL",
    xmin: 265651.36116335116,
    ymin: 90695.90138988894,
    xmax: 313042.3187001481,
    ymax: 124977.90857578155,
    zoomLevel: 12,
  },
  {
    id: 1145,
    text: "TORRIDGE DISTRICT COUNCIL",
    xmin: 190093.35870347393,
    ymin: 71643.97208981382,
    xmax: 284875.2737770678,
    ymax: 140207.98646159904,
    zoomLevel: 11,
  },
  {
    id: 1150,
    text: "WEST DEVON BOROUGH COUNCIL",
    xmin: 205839.38653021588,
    ymin: 50012.29308511462,
    xmax: 300621.3016038097,
    ymax: 118576.30745689984,
    zoomLevel: 11,
  },
  {
    id: 1155,
    text: "DEVON COUNTY COUNCIL",
    xmin: 175876.0714424344,
    ymin: 22456.744388315958,
    xmax: 365439.9015896221,
    ymax: 159584.77313188638,
    zoomLevel: 10,
  },
  {
    id: 1160,
    text: "PLYMOUTH CITY COUNCIL",
    xmin: 238229.5772660507,
    ymin: 47747.84612216706,
    xmax: 261925.05603444917,
    ymax: 64888.84971511336,
    zoomLevel: 13,
  },
  {
    id: 1165,
    text: "TORBAY COUNCIL",
    xmin: 285343.45057531423,
    ymin: 60852.01915880366,
    xmax: 297191.1899595135,
    ymax: 69422.52095527682,
    zoomLevel: 14,
  },
  {
    id: 1260,
    text: "BOURNEMOUTH, CHRISTCHURCH AND POOLE COUNCIL",
    xmin: 386507.85751071863,
    ymin: 74653.6800785425,
    xmax: 433898.81504751556,
    ymax: 108935.68726443511,
    zoomLevel: 12,
  },
  {
    id: 1265,
    text: "DORSET COUNCIL",
    xmin: 328568.59007057024,
    ymin: 62347.31852463224,
    xmax: 423350.5051441641,
    ymax: 130911.33289641746,
    zoomLevel: 11,
  },
  {
    id: 1350,
    text: "DARLINGTON BOROUGH COUNCIL",
    xmin: 423546.2208350085,
    ymin: 510754.96267343813,
    xmax: 435393.96021920774,
    ymax: 519325.4644699113,
    zoomLevel: 14,
  },
  {
    id: 1355,
    text: "DURHAM COUNTY COUNCIL",
    xmin: 365606.95339485945,
    ymin: 500459.8504262823,
    xmax: 460388.8684684533,
    ymax: 569023.8647980676,
    zoomLevel: 11,
  },
  {
    id: 1410,
    text: "EASTBOURNE BOROUGH COUNCIL",
    xmin: 549480.1174213648,
    ymin: 91778.89185232438,
    xmax: 573175.5961897633,
    ymax: 108919.89544527068,
    zoomLevel: 13,
  },
  {
    id: 1415,
    text: "HASTINGS BOROUGH COUNCIL",
    xmin: 575363.6061244261,
    ymin: 106402.25082612835,
    xmax: 587211.3455086254,
    ymax: 114972.75262260152,
    zoomLevel: 14,
  },
  {
    id: 1425,
    text: "LEWES DISTRICT COUNCIL",
    xmin: 520129.4905010283,
    ymin: 93485.76240499127,
    xmax: 567520.4480378252,
    ymax: 127767.76959088388,
    zoomLevel: 12,
  },
  {
    id: 1430,
    text: "ROTHER DISTRICT COUNCIL",
    xmin: 559265.2489830286,
    ymin: 101053.02820522155,
    xmax: 606656.2065198254,
    ymax: 135335.03539111416,
    zoomLevel: 12,
  },
  {
    id: 1435,
    text: "WEALDEN DISTRICT COUNCIL",
    xmin: 519326.90170403547,
    ymin: 81618.91376372078,
    xmax: 614108.8167776293,
    ymax: 150182.928135506,
    zoomLevel: 11,
  },
  {
    id: 1440,
    text: "EAST SUSSEX COUNTY COUNCIL",
    xmin: 518562.5314211843,
    ymin: 82689.03215971298,
    xmax: 613344.4464947782,
    ymax: 151253.0465314982,
    zoomLevel: 11,
  },
  {
    id: 1445,
    text: "BRIGHTON & HOVE CITY COUNCIL",
    xmin: 519288.6831898931,
    ymin: 97298.05919071336,
    xmax: 542984.1619582915,
    ymax: 114439.06278365967,
    zoomLevel: 13,
  },
  {
    id: 1505,
    text: "BASILDON BOROUGH COUNCIL",
    xmin: 561997.872744225,
    ymin: 180996.60516295972,
    xmax: 585693.3515126235,
    ymax: 198137.608755906,
    zoomLevel: 13,
  },
  {
    id: 1510,
    text: "BRAINTREE DISTRICT COUNCIL",
    xmin: 533811.718564073,
    ymin: 191057.62901099367,
    xmax: 628593.6336376668,
    ymax: 259621.6433827789,
    zoomLevel: 11,
  },
  {
    id: 1515,
    text: "BRENTWOOD BOROUGH COUNCIL",
    xmin: 547971.6780538987,
    ymin: 185965.01200149505,
    xmax: 571667.1568222973,
    ymax: 203106.0155944414,
    zoomLevel: 13,
  },
  {
    id: 1520,
    text: "CASTLE POINT BOROUGH COUNCIL",
    xmin: 573845.612128424,
    ymin: 182205.26567271882,
    xmax: 585693.3515126233,
    ymax: 190775.76746919198,
    zoomLevel: 14,
  },
  {
    id: 1525,
    text: "CHELMSFORD CITY COUNCIL",
    xmin: 549576.8556478875,
    ymin: 190599.0068412823,
    xmax: 596967.8131846843,
    ymax: 224881.0140271749,
    zoomLevel: 12,
  },
  {
    id: 1530,
    text: "COLCHESTER CITY COUNCIL",
    xmin: 579922.3558770947,
    ymin: 206039.28655488387,
    xmax: 627313.3134138915,
    ymax: 240321.29374077648,
    zoomLevel: 12,
  },
  {
    id: 1535,
    text: "EPPING FOREST DISTRICT COUNCIL",
    xmin: 526989.7137896252,
    ymin: 188076.5849078723,
    xmax: 574380.671326422,
    ymax: 222358.59209376492,
    zoomLevel: 12,
  },
  {
    id: 1540,
    text: "HARLOW DISTRICT COUNCIL",
    xmin: 540175.1011688143,
    ymin: 205403.90375726362,
    xmax: 552022.8405530136,
    ymax: 213974.40555373678,
    zoomLevel: 14,
  },
  {
    id: 1545,
    text: "MALDON DISTRICT COUNCIL",
    xmin: 567195.5906676179,
    ymin: 190388.80501349835,
    xmax: 614586.5482044147,
    ymax: 224670.81219939096,
    zoomLevel: 12,
  },
  {
    id: 1550,
    text: "ROCHFORD DISTRICT COUNCIL",
    xmin: 564819.7506476863,
    ymin: 173860.72274162094,
    xmax: 612210.7081844831,
    ymax: 208142.72992751354,
    zoomLevel: 12,
  },
  {
    id: 1560,
    text: "TENDRING DISTRICT COUNCIL",
    xmin: 590540.810665642,
    ymin: 206117.1486779568,
    xmax: 637931.7682024388,
    ymax: 240399.15586384942,
    zoomLevel: 12,
  },
  {
    id: 1570,
    text: "UTTLESFORD DISTRICT COUNCIL",
    xmin: 515479.6488896188,
    ymin: 193715.24083868996,
    xmax: 610261.5639632127,
    ymax: 262279.2552104752,
    zoomLevel: 11,
  },
  {
    id: 1585,
    text: "ESSEX COUNTY COUNCIL",
    xmin: 537952.1352054521,
    ymin: 179115.76843622504,
    xmax: 632734.050279046,
    ymax: 247679.78280801026,
    zoomLevel: 11,
  },
  {
    id: 1590,
    text: "SOUTHEND-ON-SEA CITY COUNCIL",
    xmin: 582753.7884090887,
    ymin: 182646.20368014596,
    xmax: 594601.527793288,
    ymax: 191216.70547661913,
    zoomLevel: 14,
  },
  {
    id: 1595,
    text: "THURROCK COUNCIL",
    xmin: 552484.7252081664,
    ymin: 172914.81451659166,
    xmax: 576180.2039765649,
    ymax: 190055.81810953794,
    zoomLevel: 13,
  },
  {
    id: 1600,
    text: "GLOUCESTERSHIRE COUNTY COUNCIL",
    xmin: 339993.65128545486,
    ymin: 175707.2923603485,
    xmax: 434775.5663590487,
    ymax: 244271.30673213373,
    zoomLevel: 11,
  },
  {
    id: 1605,
    text: "CHELTENHAM BOROUGH COUNCIL",
    xmin: 388664.9290460282,
    ymin: 217637.7796890247,
    xmax: 400512.66843022744,
    ymax: 226208.28148549786,
    zoomLevel: 14,
  },
  {
    id: 1610,
    text: "COTSWOLD DISTRICT COUNCIL",
    xmin: 366574.62787161756,
    ymin: 181449.62411027055,
    xmax: 461356.5429452114,
    ymax: 250013.63848205577,
    zoomLevel: 11,
  },
  {
    id: 1615,
    text: "FOREST OF DEAN DISTRICT COUNCIL",
    xmin: 325795.4732814858,
    ymin: 179194.7317758582,
    xmax: 420577.38835507963,
    ymax: 247758.74614764343,
    zoomLevel: 11,
  },
  {
    id: 1620,
    text: "GLOUCESTER CITY COUNCIL",
    xmin: 378212.1654280315,
    ymin: 212248.96919492073,
    xmax: 390059.9048122308,
    ymax: 220819.4709913939,
    zoomLevel: 14,
  },
  {
    id: 1625,
    text: "STROUD DISTRICT COUNCIL",
    xmin: 357058.2178501147,
    ymin: 184679.08855531848,
    xmax: 404449.1753869116,
    ymax: 218961.0957412111,
    zoomLevel: 12,
  },
  {
    id: 1630,
    text: "TEWKESBURY BOROUGH COUNCIL",
    xmin: 370625.7903707286,
    ymin: 208183.474753004,
    xmax: 418016.7479075255,
    ymax: 242465.4819388966,
    zoomLevel: 12,
  },
  {
    id: 1705,
    text: "BASINGSTOKE AND DEANE BOROUGH COUNCIL",
    xmin: 432960.1869372745,
    ymin: 133504.49811840637,
    xmax: 480351.14447407145,
    ymax: 167786.50530429897,
    zoomLevel: 12,
  },
  {
    id: 1710,
    text: "EAST HAMPSHIRE DISTRICT COUNCIL",
    xmin: 428641.49483916315,
    ymin: 92820.88981363177,
    xmax: 523423.409912757,
    ymax: 161384.904185417,
    zoomLevel: 11,
  },
  {
    id: 1715,
    text: "EASTLEIGH BOROUGH COUNCIL",
    xmin: 436017.6680686807,
    ymin: 106130.48736378446,
    xmax: 459713.1468370791,
    ymax: 123271.49095673076,
    zoomLevel: 13,
  },
  {
    id: 1720,
    text: "FAREHAM BOROUGH COUNCIL",
    xmin: 444884.36334975896,
    ymin: 97225.57356856368,
    xmax: 468579.84211815736,
    ymax: 114366.57716150998,
    zoomLevel: 13,
  },
  {
    id: 1725,
    text: "GOSPORT BOROUGH COUNCIL",
    xmin: 453712.84011669405,
    ymin: 96953.26665529795,
    xmax: 465560.5795008933,
    ymax: 105523.76845177109,
    zoomLevel: 14,
  },
  {
    id: 1730,
    text: "HART DISTRICT COUNCIL",
    xmin: 452852.92354848527,
    ymin: 135272.1043974998,
    xmax: 500243.8810852822,
    ymax: 169554.1115833924,
    zoomLevel: 12,
  },
  {
    id: 1735,
    text: "HAVANT BOROUGH COUNCIL",
    xmin: 460786.2099471181,
    ymin: 96556.89841099163,
    xmax: 484481.68871551653,
    ymax: 113697.90200393794,
    zoomLevel: 13,
  },
  {
    id: 1740,
    text: "NEW FOREST DISTRICT COUNCIL",
    xmin: 401662.16856855043,
    ymin: 88922.7502110116,
    xmax: 449053.12610534736,
    ymax: 123204.75739690421,
    zoomLevel: 12,
  },
  {
    id: 1750,
    text: "RUSHMOOR BOROUGH COUNCIL",
    xmin: 473923.8241836302,
    ymin: 144884.20954428118,
    xmax: 497619.3029520286,
    ymax: 162025.21313722746,
    zoomLevel: 13,
  },
  {
    id: 1760,
    text: "TEST VALLEY BOROUGH COUNCIL",
    xmin: 395760.55397950124,
    ymin: 102531.92908718853,
    xmax: 490542.4690530951,
    ymax: 171095.94345897375,
    zoomLevel: 11,
  },
  {
    id: 1765,
    text: "WINCHESTER CITY COUNCIL",
    xmin: 409939.62272639736,
    ymin: 88238.2047978643,
    xmax: 504721.5377999912,
    ymax: 156802.21916964953,
    zoomLevel: 11,
  },
  {
    id: 1770,
    text: "HAMPSHIRE COUNTY COUNCIL",
    xmin: 354140.59207823325,
    ymin: 60224.03393135479,
    xmax: 543704.422225421,
    ymax: 197352.06267492523,
    zoomLevel: 10,
  },
  {
    id: 1775,
    text: "PORTSMOUTH CITY COUNCIL",
    xmin: 454559.73798785784,
    ymin: 93818.107862681,
    xmax: 478255.21675625624,
    ymax: 110959.11145562731,
    zoomLevel: 13,
  },
  {
    id: 1780,
    text: "SOUTHAMPTON CITY COUNCIL",
    xmin: 435192.50594610604,
    ymin: 109244.05563347903,
    xmax: 447040.2453303053,
    ymax: 117814.5574299522,
    zoomLevel: 14,
  },
  {
    id: 1805,
    text: "BROMSGROVE DISTRICT COUNCIL",
    xmin: 387495.8002961693,
    ymin: 264272.6809385796,
    xmax: 411191.2790645677,
    ymax: 281413.68453152594,
    zoomLevel: 13,
  },
  {
    id: 1820,
    text: "MALVERN HILLS DISTRICT COUNCIL",
    xmin: 328906.8181155964,
    ymin: 220904.22201528936,
    xmax: 423688.7331891903,
    ymax: 289468.2363870746,
    zoomLevel: 11,
  },
  {
    id: 1825,
    text: "REDDITCH BOROUGH COUNCIL",
    xmin: 392043.8034791363,
    ymin: 255826.3893130701,
    xmax: 415739.2822475347,
    ymax: 272967.39290601644,
    zoomLevel: 13,
  },
  {
    id: 1835,
    text: "WORCESTER CITY COUNCIL",
    xmin: 379498.5762118351,
    ymin: 250977.41533123018,
    xmax: 391346.31559603434,
    ymax: 259547.91712770335,
    zoomLevel: 14,
  },
  {
    id: 1840,
    text: "WYCHAVON DISTRICT COUNCIL",
    xmin: 347662.55393106694,
    ymin: 221286.4071567148,
    xmax: 442444.4690046608,
    ymax: 289850.42152850004,
    zoomLevel: 11,
  },
  {
    id: 1845,
    text: "WYRE FOREST DISTRICT COUNCIL",
    xmin: 369007.594079697,
    ymin: 267177.2880134156,
    xmax: 392703.0728480954,
    ymax: 284318.2916063619,
    zoomLevel: 13,
  },
  {
    id: 1850,
    text: "HEREFORDSHIRE COUNCIL",
    xmin: 257925.48272429436,
    ymin: 174430.50881791415,
    xmax: 447489.31287148205,
    ymax: 311558.5375614846,
    zoomLevel: 10,
  },
  {
    id: 1855,
    text: "WORCESTERSHIRE COUNTY COUNCIL",
    xmin: 339407.354876271,
    ymin: 224229.23274569362,
    xmax: 434189.2699498648,
    ymax: 292793.24711747887,
    zoomLevel: 11,
  },
  {
    id: 1900,
    text: "HERTFORDSHIRE COUNTY COUNCIL",
    xmin: 472331.34706415766,
    ymin: 182035.9931322869,
    xmax: 567113.2621377514,
    ymax: 250600.00750407213,
    zoomLevel: 11,
  },
  {
    id: 1905,
    text: "BROXBOURNE BOROUGH COUNCIL",
    xmin: 524308.5262980647,
    ymin: 196778.78496278642,
    xmax: 548004.0050664633,
    ymax: 213919.7885557327,
    zoomLevel: 13,
  },
  {
    id: 1910,
    text: "DACORUM BOROUGH COUNCIL",
    xmin: 474987.53379706864,
    ymin: 194036.60657305614,
    xmax: 522378.49133386556,
    ymax: 228318.61375894875,
    zoomLevel: 12,
  },
  {
    id: 1915,
    text: "EAST HERTFORDSHIRE DISTRICT COUNCIL",
    xmin: 514983.2088472767,
    ymin: 202788.64631170672,
    xmax: 562374.1663840736,
    ymax: 237070.65349759933,
    zoomLevel: 12,
  },
  {
    id: 1920,
    text: "HERTSMERE BOROUGH COUNCIL",
    xmin: 506919.10236319393,
    ymin: 191046.00784139917,
    xmax: 530614.5811315924,
    ymax: 208187.0114343455,
    zoomLevel: 13,
  },
  {
    id: 1925,
    text: "NORTH HERTFORDSHIRE DISTRICT COUNCIL",
    xmin: 500689.48455795244,
    ymin: 212209.51004785133,
    xmax: 548080.4420947493,
    ymax: 246491.51723374394,
    zoomLevel: 12,
  },
  {
    id: 1930,
    text: "ST ALBANS CITY AND DISTRICT COUNCIL",
    xmin: 503613.2008898605,
    ymin: 200065.577179047,
    xmax: 527308.6796582589,
    ymax: 217206.58077199332,
    zoomLevel: 13,
  },
  {
    id: 1935,
    text: "STEVENAGE BOROUGH COUNCIL",
    xmin: 519387.8926022097,
    ymin: 219456.69579213776,
    xmax: 531235.631986409,
    ymax: 228027.19758861093,
    zoomLevel: 14,
  },
  {
    id: 1940,
    text: "THREE RIVERS DISTRICT COUNCIL",
    xmin: 495501.3212630985,
    ymin: 189154.1913913411,
    xmax: 519196.8000314969,
    ymax: 206295.19498428737,
    zoomLevel: 13,
  },
  {
    id: 1945,
    text: "WATFORD BOROUGH COUNCIL",
    xmin: 505677.00065355975,
    ymin: 193773.85428832527,
    xmax: 517524.740037759,
    ymax: 202344.35608479843,
    zoomLevel: 14,
  },
  {
    id: 1950,
    text: "WELWYN HATFIELD BOROUGH COUNCIL",
    xmin: 502103.56958122907,
    ymin: 191313.53744039667,
    xmax: 549494.5271180259,
    ymax: 225595.54462628928,
    zoomLevel: 12,
  },
  {
    id: 2001,
    text: "EAST RIDING OF YORKSHIRE COUNCIL",
    xmin: 450310.44388909574,
    ymin: 407601.48876491206,
    xmax: 545092.3589626895,
    ymax: 476165.5031366973,
    zoomLevel: 11,
  },
  {
    id: 2002,
    text: "NORTH EAST LINCOLNSHIRE COUNCIL",
    xmin: 499383.0160481662,
    ymin: 387708.7521537001,
    xmax: 546773.9735849631,
    ymax: 421990.75933959277,
    zoomLevel: 12,
  },
  {
    id: 2003,
    text: "NORTH LINCOLNSHIRE COUNCIL",
    xmin: 448972.79589410545,
    ymin: 376338.7441962837,
    xmax: 543754.7109676993,
    ymax: 444902.7585680689,
    zoomLevel: 11,
  },
  {
    id: 2004,
    text: "HULL CITY COUNCIL",
    xmin: 497242.7792561818,
    ymin: 423032.2138499787,
    xmax: 520938.2580245802,
    ymax: 440173.21744292503,
    zoomLevel: 13,
  },
  {
    id: 2114,
    text: "ISLE OF WIGHT COUNCIL",
    xmin: 423892.647507709,
    ymin: 69549.09094351094,
    xmax: 471283.6050445059,
    ymax: 103831.09812940354,
    zoomLevel: 12,
  },
  {
    id: 2205,
    text: "ASHFORD BOROUGH COUNCIL",
    xmin: 574045.3162413699,
    ymin: 124087.42220789331,
    xmax: 621436.2737781667,
    ymax: 158369.42939378592,
    zoomLevel: 12,
  },
  {
    id: 2210,
    text: "CANTERBURY CITY COUNCIL",
    xmin: 591052.5550348168,
    ymin: 140827.1314023425,
    xmax: 638443.5125716137,
    ymax: 175109.1385882351,
    zoomLevel: 12,
  },
  {
    id: 2215,
    text: "DARTFORD BOROUGH COUNCIL",
    xmin: 546585.3138299283,
    ymin: 163920.66857299506,
    xmax: 570280.7925983268,
    ymax: 181061.67216594133,
    zoomLevel: 13,
  },
  {
    id: 2220,
    text: "DOVER DISTRICT COUNCIL",
    xmin: 607677.6086868397,
    ymin: 135037.0265097416,
    xmax: 655068.5662236365,
    ymax: 169319.03369563422,
    zoomLevel: 12,
  },
  {
    id: 2230,
    text: "GRAVESHAM BOROUGH COUNCIL",
    xmin: 553942.3778023749,
    ymin: 160041.48938752338,
    xmax: 577637.8565707734,
    ymax: 177182.49298046966,
    zoomLevel: 13,
  },
  {
    id: 2235,
    text: "MAIDSTONE BOROUGH COUNCIL",
    xmin: 558089.0865868422,
    ymin: 133986.01737082063,
    xmax: 605480.044123639,
    ymax: 168268.02455671324,
    zoomLevel: 12,
  },
  {
    id: 2245,
    text: "SEVENOAKS DISTRICT COUNCIL",
    xmin: 528087.5529849184,
    ymin: 136890.6244456567,
    xmax: 575478.5105217152,
    ymax: 171172.6316315493,
    zoomLevel: 12,
  },
  {
    id: 2250,
    text: "FOLKESTONE & HYTHE DISTRICT COUNCIL",
    xmin: 582802.1247367982,
    ymin: 115146.27401087742,
    xmax: 630193.082273595,
    ymax: 149428.28119677003,
    zoomLevel: 12,
  },
  {
    id: 2255,
    text: "SWALE BOROUGH COUNCIL",
    xmin: 575158.421908282,
    ymin: 145950.39640979562,
    xmax: 622549.3794450788,
    ymax: 180232.40359568823,
    zoomLevel: 12,
  },
  {
    id: 2260,
    text: "THANET DISTRICT COUNCIL",
    xmin: 619262.5872288159,
    ymin: 156584.69796996762,
    xmax: 642958.0659972144,
    ymax: 173725.7015629139,
    zoomLevel: 13,
  },
  {
    id: 2265,
    text: "TONBRIDGE & MALLING BOROUGH COUNCIL",
    xmin: 542901.9959719459,
    ymin: 138994.6268358456,
    xmax: 590292.9535087427,
    ymax: 173276.63402173822,
    zoomLevel: 12,
  },
  {
    id: 2270,
    text: "TUNBRIDGE WELLS BOROUGH COUNCIL",
    xmin: 546685.6288720621,
    ymin: 119656.05867970127,
    xmax: 594076.5864088589,
    ymax: 153938.06586559388,
    zoomLevel: 12,
  },
  {
    id: 2275,
    text: "KENT COUNTY COUNCIL",
    xmin: 481408.40671653766,
    ymin: 71749.15120197932,
    xmax: 670972.2368637254,
    ymax: 208877.17994554975,
    zoomLevel: 10,
  },
  {
    id: 2280,
    text: "MEDWAY COUNCIL",
    xmin: 556775.3166057016,
    ymin: 151950.70313017978,
    xmax: 604166.2741424984,
    ymax: 186232.7103160724,
    zoomLevel: 12,
  },
  {
    id: 2315,
    text: "BURNLEY BOROUGH COUNCIL",
    xmin: 371358.1952429846,
    ymin: 422490.0101169547,
    xmax: 395053.67401138303,
    ymax: 439631.013709901,
    zoomLevel: 13,
  },
  {
    id: 2320,
    text: "CHORLEY BOROUGH COUNCIL",
    xmin: 333904.05138325784,
    ymin: 400275.49877158133,
    xmax: 381295.00892005477,
    ymax: 434557.5059574739,
    zoomLevel: 12,
  },
  {
    id: 2325,
    text: "FYLDE BOROUGH COUNCIL",
    xmin: 318234.46058480145,
    ymin: 418792.36887365975,
    xmax: 365625.41812159837,
    ymax: 453074.3760595524,
    zoomLevel: 12,
  },
  {
    id: 2330,
    text: "HYNDBURN BOROUGH COUNCIL",
    xmin: 363064.7776740461,
    ymin: 420177.79001132806,
    xmax: 386760.2564424445,
    ymax: 437318.7936042744,
    zoomLevel: 13,
  },
  {
    id: 2335,
    text: "LANCASTER CITY COUNCIL",
    xmin: 341691.0736398091,
    ymin: 457006.1057019693,
    xmax: 353538.81302400836,
    ymax: 465576.60749844246,
    zoomLevel: 14,
  },
  {
    id: 2340,
    text: "PENDLE BOROUGH COUNCIL",
    xmin: 373259.56632157863,
    ymin: 434012.8921309401,
    xmax: 396955.04508997704,
    ymax: 451153.89572388644,
    zoomLevel: 13,
  },
  {
    id: 2345,
    text: "PRESTON CITY COUNCIL",
    xmin: 342722.9735216589,
    ymin: 428203.67798126914,
    xmax: 366418.4522900573,
    ymax: 445344.6815742155,
    zoomLevel: 13,
  },
  {
    id: 2350,
    text: "RIBBLE VALLEY BOROUGH COUNCIL",
    xmin: 348264.65807233145,
    ymin: 428155.90483858914,
    xmax: 395655.6156091284,
    ymax: 462437.9120244818,
    zoomLevel: 12,
  },
  {
    id: 2355,
    text: "ROSSENDALE BOROUGH COUNCIL",
    xmin: 369284.8408507492,
    ymin: 412151.9020413842,
    xmax: 392980.31961914763,
    ymax: 429292.9056343305,
    zoomLevel: 13,
  },
  {
    id: 2360,
    text: "SOUTH RIBBLE BOROUGH COUNCIL",
    xmin: 342063.70415269805,
    ymin: 418123.5448761616,
    xmax: 365759.18292109645,
    ymax: 435264.54846910795,
    zoomLevel: 13,
  },
  {
    id: 2365,
    text: "WEST LANCASHIRE BOROUGH COUNCIL",
    xmin: 321578.5805722765,
    ymin: 395106.4447337943,
    xmax: 368969.5381090734,
    ymax: 429388.451919687,
    zoomLevel: 12,
  },
  {
    id: 2370,
    text: "WYRE BOROUGH COUNCIL",
    xmin: 319476.5622944347,
    ymin: 425834.130104427,
    xmax: 366867.5198312316,
    ymax: 460116.13729031966,
    zoomLevel: 12,
  },
  {
    id: 2371,
    text: "LANCASHIRE COUNTY COUNCIL",
    xmin: 262913.1613634183,
    ymin: 366920.2905536427,
    xmax: 452476.99151060596,
    ymax: 504048.31929721317,
    zoomLevel: 10,
  },
  {
    id: 2372,
    text: "BLACKBURN WITH DARWEN BOROUGH COUNCIL",
    xmin: 361832.23059294594,
    ymin: 423956.6455971727,
    xmax: 373679.9699771452,
    ymax: 432527.1473936459,
    zoomLevel: 14,
  },
  {
    id: 2373,
    text: "BLACKPOOL BOROUGH COUNCIL",
    xmin: 321439.8429820593,
    ymin: 428219.104057636,
    xmax: 345135.3217504577,
    ymax: 445360.10765058233,
    zoomLevel: 13,
  },
  {
    id: 2405,
    text: "BLABY DISTRICT COUNCIL",
    xmin: 428826.2633998565,
    ymin: 281933.09396946686,
    xmax: 476217.22093665344,
    ymax: 316215.10115535953,
    zoomLevel: 12,
  },
  {
    id: 2410,
    text: "CHARNWOOD BOROUGH COUNCIL",
    xmin: 433278.720297468,
    ymin: 301558.3009816796,
    xmax: 480669.67783426493,
    ymax: 335840.30816757225,
    zoomLevel: 12,
  },
  {
    id: 2415,
    text: "HARBOROUGH DISTRICT COUNCIL",
    xmin: 422577.5363375462,
    ymin: 263244.2405537451,
    xmax: 517359.45141114003,
    ymax: 331808.25492553035,
    zoomLevel: 11,
  },
  {
    id: 2420,
    text: "HINCKLEY & BOSWORTH BOROUGH COUNCIL",
    xmin: 418449.9368101474,
    ymin: 285277.21395694115,
    xmax: 465840.89434694435,
    ymax: 319559.2211428337,
    zoomLevel: 12,
  },
  {
    id: 2430,
    text: "MELTON BOROUGH COUNCIL",
    xmin: 429495.0873973524,
    ymin: 294086.58146680525,
    xmax: 524277.00247094623,
    ymax: 362650.59583859047,
    zoomLevel: 11,
  },
  {
    id: 2435,
    text: "NORTH WEST LEICESTERSHIRE DISTRICT COUNCIL",
    xmin: 422080.69565369276,
    ymin: 304042.50440094736,
    xmax: 469471.6531904897,
    ymax: 338324.5115868399,
    zoomLevel: 12,
  },
  {
    id: 2440,
    text: "OADBY AND WIGSTON BOROUGH COUNCIL",
    xmin: 454136.4743907796,
    ymin: 294597.7540934633,
    xmax: 465984.2137749789,
    ymax: 303168.25588993647,
    zoomLevel: 14,
  },
  {
    id: 2460,
    text: "LEICESTERSHIRE COUNTY COUNCIL",
    xmin: 420007.34126145666,
    ymin: 275302.1817657292,
    xmax: 514789.2563350505,
    ymax: 343866.19613751443,
    zoomLevel: 11,
  },
  {
    id: 2465,
    text: "LEICESTER CITY COUNCIL",
    xmin: 447792.20104311116,
    ymin: 295815.9692317585,
    xmax: 471487.67981150956,
    ymax: 312956.97282470483,
    zoomLevel: 13,
  },
  {
    id: 2470,
    text: "RUTLAND COUNTY COUNCIL",
    xmin: 469385.66153366736,
    ymin: 287684.98034792463,
    xmax: 516776.6190704643,
    ymax: 321966.9875338172,
    zoomLevel: 12,
  },
  {
    id: 2500,
    text: "LINCOLNSHIRE COUNTY COUNCIL",
    xmin: 418325.7266391821,
    ymin: 303679.4285165938,
    xmax: 607889.5567863698,
    ymax: 440807.45726016426,
    zoomLevel: 10,
  },
  {
    id: 2505,
    text: "BOSTON BOROUGH COUNCIL",
    xmin: 510623.438293509,
    ymin: 327279.3609996351,
    xmax: 558014.3958303059,
    ymax: 361561.3681855276,
    zoomLevel: 12,
  },
  {
    id: 2510,
    text: "EAST LINDSEY DISTRICT COUNCIL",
    xmin: 485361.0004452653,
    ymin: 340980.6983197484,
    xmax: 580142.9155188592,
    ymax: 409544.7126915336,
    zoomLevel: 11,
  },
  {
    id: 2515,
    text: "CITY OF LINCOLN COUNCIL",
    xmin: 491131.9960807946,
    ymin: 365712.8542842638,
    xmax: 502979.73546499386,
    ymax: 374283.35608073696,
    zoomLevel: 14,
  },
  {
    id: 2520,
    text: "NORTH KESTEVEN DISTRICT COUNCIL",
    xmin: 457002.86295147176,
    ymin: 312804.09876813146,
    xmax: 551784.7780250655,
    ymax: 381368.1131399167,
    zoomLevel: 11,
  },
  {
    id: 2525,
    text: "SOUTH HOLLAND DISTRICT COUNCIL",
    xmin: 510699.8753217934,
    ymin: 304644.4459986908,
    xmax: 558090.8328585903,
    ymax: 338926.45318458346,
    zoomLevel: 12,
  },
  {
    id: 2530,
    text: "SOUTH KESTEVEN DISTRICT COUNCIL",
    xmin: 459334.19231416855,
    ymin: 295338.2378049728,
    xmax: 554116.1073877624,
    ymax: 363902.252176758,
    zoomLevel: 11,
  },
  {
    id: 2535,
    text: "WEST LINDSEY DISTRICT COUNCIL",
    xmin: 456506.0222676169,
    ymin: 356717.1715179528,
    xmax: 551287.9373412107,
    ymax: 425281.18588973803,
    zoomLevel: 11,
  },
  {
    id: 2600,
    text: "NORFOLK COUNTY COUNCIL",
    xmin: 503514.7946629877,
    ymin: 228494.05656960385,
    xmax: 693078.6248101754,
    ymax: 365622.0853131743,
    zoomLevel: 10,
  },
  {
    id: 2605,
    text: "BRECKLAND DISTRICT COUNCIL",
    xmin: 547618.9599835244,
    ymin: 268967.4630465941,
    xmax: 642400.8750571182,
    ymax: 337531.47741837933,
    zoomLevel: 11,
  },
  {
    id: 2610,
    text: "BROADLAND DISTRICT COUNCIL",
    xmin: 576512.1566753149,
    ymin: 284981.0204723335,
    xmax: 671294.0717489087,
    ymax: 353545.0348441187,
    zoomLevel: 11,
  },
  {
    id: 2615,
    text: "GREAT YARMOUTH BOROUGH COUNCIL",
    xmin: 625202.5436929603,
    ymin: 293828.6064963405,
    xmax: 672593.5012297571,
    ymax: 328110.61368223315,
    zoomLevel: 12,
  },
  {
    id: 2620,
    text: "NORTH NORFOLK DISTRICT COUNCIL",
    xmin: 570015.0092710775,
    ymin: 296943.41539896076,
    xmax: 664796.9243446713,
    ymax: 365507.429770746,
    zoomLevel: 11,
  },
  {
    id: 2625,
    text: "NORWICH CITY COUNCIL",
    xmin: 612074.4840849853,
    ymin: 300889.4769841817,
    xmax: 635769.9628533839,
    ymax: 318030.480577128,
    zoomLevel: 13,
  },
  {
    id: 2630,
    text: "SOUTH NORFOLK DISTRICT COUNCIL",
    xmin: 577295.6362152386,
    ymin: 263100.921125708,
    xmax: 672077.5512888324,
    ymax: 331664.9354974932,
    zoomLevel: 11,
  },
  {
    id: 2635,
    text: "BOROUGH OF KINGS LYN AND WEST NORFOLK",
    xmin: 526006.3902358998,
    ymin: 281063.62277272,
    xmax: 620788.3053094937,
    ymax: 349627.6371445052,
    zoomLevel: 11,
  },
  {
    id: 2741,
    text: "CITY OF YORK COUNCIL",
    xmin: 454289.7171631487,
    ymin: 447685.11623337946,
    xmax: 466137.45654734795,
    ymax: 456255.61802985263,
    zoomLevel: 14,
  },
  {
    id: 2745,
    text: "NORTH YORKSHIRE COUNCIL",
    xmin: 343112.0595223895,
    ymin: 398388.0103037222,
    xmax: 532675.8896695771,
    ymax: 535516.0390472927,
    zoomLevel: 10,
  },
  {
    id: 2840,
    text: "NORTH NORTHAMPTONSHIRE COUNCIL",
    xmin: 445761.2536513332,
    ymin: 247226.06550449843,
    xmax: 540543.168724927,
    ymax: 315790.07987628365,
    zoomLevel: 11,
  },
  {
    id: 2845,
    text: "WEST NORTHAMPTONSHIRE COUNCIL",
    xmin: 417326.6791292548,
    ymin: 222307.59428353744,
    xmax: 512108.5942028486,
    ymax: 290871.6086553227,
    zoomLevel: 11,
  },
  {
    id: 2935,
    text: "NORTHUMBERLAND COUNTY COUNCIL",
    xmin: 297956.88506293675,
    ymin: 536739.0314998564,
    xmax: 487520.71521012444,
    ymax: 673867.0602434268,
    zoomLevel: 10,
  },
  {
    id: 3005,
    text: "ASHFIELD DISTRICT COUNCIL",
    xmin: 437671.1020326551,
    ymin: 346207.03477087914,
    xmax: 461366.5808010535,
    ymax: 363348.0383638255,
    zoomLevel: 13,
  },
  {
    id: 3010,
    text: "BASSETLAW DISTRICT COUNCIL",
    xmin: 441569.3904751973,
    ymin: 367446.9740056158,
    xmax: 488960.3480119942,
    ymax: 401728.98119150836,
    zoomLevel: 12,
  },
  {
    id: 3015,
    text: "BROXTOWE BOROUGH COUNCIL",
    xmin: 426052.6737333113,
    ymin: 325119.96959271096,
    xmax: 473443.6312701082,
    ymax: 359401.9767786035,
    zoomLevel: 12,
  },
  {
    id: 3020,
    text: "GEDLING BOROUGH COUNCIL",
    xmin: 434690.05792953423,
    ymin: 331617.1169969494,
    xmax: 482081.01546633116,
    ymax: 365899.124182842,
    zoomLevel: 12,
  },
  {
    id: 3025,
    text: "MANSFIELD DISTRICT COUNCIL",
    xmin: 443365.6606398997,
    ymin: 355417.69667923986,
    xmax: 467061.1394082981,
    ymax: 372558.7002721862,
    zoomLevel: 13,
  },
  {
    id: 3030,
    text: "NEWARK & SHERWOOD DISTRICT COUNCIL",
    xmin: 446079.1751440216,
    ymin: 341229.0733038081,
    xmax: 493470.1326808185,
    ymax: 375511.08048970066,
    zoomLevel: 12,
  },
  {
    id: 3040,
    text: "RUSHCLIFFE BOROUGH COUNCIL",
    xmin: 441301.8608761996,
    ymin: 316004.8539697067,
    xmax: 488692.81841299654,
    ymax: 350286.86115559936,
    zoomLevel: 12,
  },
  {
    id: 3055,
    text: "NOTTINGHAMSHIRE COUNTY COUNCIL",
    xmin: 367463.6915527386,
    ymin: 299207.8170040433,
    xmax: 557027.5216999262,
    ymax: 436335.84574761376,
    zoomLevel: 10,
  },
  {
    id: 3060,
    text: "NOTTINGHAM CITY COUNCIL",
    xmin: 444624.41594291927,
    ymin: 331481.6600663783,
    xmax: 468319.89471131767,
    ymax: 348622.66365932464,
    zoomLevel: 13,
  },
  {
    id: 3100,
    text: "OXFORDSHIRE COUNTY COUNCIL",
    xmin: 400290.93953752867,
    ymin: 182630.10210957157,
    xmax: 495072.8546111225,
    ymax: 251194.1164813568,
    zoomLevel: 11,
  },
  {
    id: 3105,
    text: "CHERWELL DISTRICT COUNCIL",
    xmin: 397692.080575833,
    ymin: 188974.3754572394,
    xmax: 492473.9956494268,
    ymax: 257538.3898290246,
    zoomLevel: 11,
  },
  {
    id: 3110,
    text: "OXFORD CITY COUNCIL",
    xmin: 440821.6737857324,
    ymin: 198137.26422292256,
    xmax: 464517.1525541308,
    ymax: 215278.26781586884,
    zoomLevel: 13,
  },
  {
    id: 3115,
    text: "SOUTH OXFORDSHIRE DISTRICT COUNCIL",
    xmin: 414431.7897702819,
    ymin: 158342.2363719628,
    xmax: 509213.7048438757,
    ymax: 226906.250743748,
    zoomLevel: 11,
  },
  {
    id: 3120,
    text: "VALE OF WHITE HORSE DISTRICT COUNCIL",
    xmin: 413094.14177529194,
    ymin: 180872.0504590127,
    xmax: 460485.09931208886,
    ymax: 215154.05764490532,
    zoomLevel: 12,
  },
  {
    id: 3125,
    text: "WEST OXFORDSHIRE DISTRICT COUNCIL",
    xmin: 412406.20852072566,
    ymin: 199140.50021916485,
    xmax: 459797.1660575226,
    ymax: 233422.50740505746,
    zoomLevel: 12,
  },
  {
    id: 3240,
    text: "TELFORD & WREKIN COUNCIL",
    xmin: 346826.8018611737,
    ymin: 295701.6933726375,
    xmax: 394217.7593979706,
    ymax: 329983.70055853005,
    zoomLevel: 12,
  },
  {
    id: 3245,
    text: "SHROPSHIRE COUNCIL",
    xmin: 300926.36637593684,
    ymin: 278713.56383626215,
    xmax: 395708.2814495307,
    ymax: 347277.5782080474,
    zoomLevel: 11,
  },
  {
    id: 3300,
    text: "SOMERSET COUNCIL",
    xmin: 236256.54149863299,
    ymin: 69702.5797019086,
    xmax: 425820.3716458207,
    ymax: 206830.60844547904,
    zoomLevel: 10,
  },
  {
    id: 3405,
    text: "CANNOCK CHASE DISTRICT COUNCIL",
    xmin: 389773.0228041416,
    ymin: 303921.751454278,
    xmax: 413468.50157254003,
    ymax: 321062.75504722435,
    zoomLevel: 13,
  },
  {
    id: 3410,
    text: "EAST STAFFORDSHIRE BOROUGH COUNCIL",
    xmin: 367818.3404671643,
    ymin: 300483.45703038416,
    xmax: 462600.25554075814,
    ymax: 369047.4714021694,
  },
  {
    id: 3415,
    text: "LICHFIELD DISTRICT COUNCIL",
    xmin: 391666.69329213246,
    ymin: 293699.6707700767,
    xmax: 439057.6508289294,
    ymax: 327981.67795596924,
  },
  {
    id: 3420,
    text: "NEWCASTLE-UNDER-LYME BOROUGH COUNCIL",
    xmin: 357461.1231345264,
    ymin: 328554.9556681077,
    xmax: 404852.08067132335,
    ymax: 362836.9628540003,
    zoomLevel: 12,
  },
  {
    id: 3425,
    text: "STAFFORD BOROUGH COUNCIL",
    xmin: 365754.5407034657,
    ymin: 310362.94293624046,
    xmax: 413145.4982402626,
    ymax: 344644.95012213313,
    zoomLevel: 12,
  },
  {
    id: 3430,
    text: "SOUTH STAFFORDSHIRE COUNCIL",
    xmin: 343855.33209976787,
    ymin: 265704.6091606382,
    xmax: 438637.2471733617,
    ymax: 334268.6235324234,
    zoomLevel: 11,
  },
  {
    id: 3435,
    text: "STAFFORDSHIRE MOORLANDS DISTRICT COUNCIL",
    xmin: 376837.90980481374,
    ymin: 335090.3215864883,
    xmax: 424228.86734161066,
    ymax: 369372.32877238095,
    zoomLevel: 12,
  },
  {
    id: 3445,
    text: "TAMWORTH BOROUGH COUNCIL",
    xmin: 415878.1220014571,
    ymin: 298242.8966387754,
    xmax: 427725.86138565635,
    ymax: 306813.3984352486,
    zoomLevel: 14,
  },
  {
    id: 3450,
    text: "STAFFORDSHIRE COUNTY COUNCIL",
    xmin: 312191.29313264386,
    ymin: 259035.4784427579,
    xmax: 501755.12327983155,
    ymax: 396163.50718632835,
    zoomLevel: 10,
  },
  {
    id: 3455,
    text: "STOKE-ON-TRENT CITY COUNCIL",
    xmin: 376551.2709487446,
    ymin: 339323.02202777826,
    xmax: 400246.749717143,
    ymax: 356464.0256207246,
    zoomLevel: 13,
  },
  {
    id: 3500,
    text: "SUFFOLK COUNTY COUNCIL",
    xmin: 561315.6853624585,
    ymin: 229693.70105963916,
    xmax: 656097.6004360523,
    ymax: 298257.7154314244,
    zoomLevel: 11,
  },
  {
    id: 3505,
    text: "BABERGH DISTRICT COUNCIL",
    xmin: 579698.7906650393,
    ymin: 227496.136496441,
    xmax: 627089.7482018361,
    ymax: 261778.1436823336,
    zoomLevel: 12,
  },
  {
    id: 3515,
    text: "IPSWICH BOROUGH COUNCIL",
    xmin: 610779.997291491,
    ymin: 240208.56976311628,
    xmax: 622627.7366756903,
    ymax: 248779.07155958944,
    zoomLevel: 14,
  },
  {
    id: 3520,
    text: "MID SUFFOLK DISTRICT COUNCIL",
    xmin: 564573.813693114,
    ymin: 230888.02962659526,
    xmax: 659355.7287667078,
    ymax: 299452.0439983805,
    zoomLevel: 11,
  },
  {
    id: 3540,
    text: "EAST SUFFOLK COUNCIL",
    xmin: 525193.2766725364,
    ymin: 208821.01435362943,
    xmax: 714757.1068197241,
    ymax: 345949.0430971999,
    zoomLevel: 10,
  },
  {
    id: 3545,
    text: "WEST SUFFOLK COUNCIL",
    xmin: 536024.583628609,
    ymin: 229397.50757503492,
    xmax: 630806.4987022028,
    ymax: 297961.52194682014,
    zoomLevel: 11,
  },
  {
    id: 3600,
    text: "SURREY COUNTY COUNCIL",
    xmin: 465498.7681142385,
    ymin: 120043.35530655793,
    xmax: 560280.6831878324,
    ymax: 188607.36967834315,
    zoomLevel: 11,
  },
  {
    id: 3605,
    text: "ELMBRIDGE BOROUGH COUNCIL",
    xmin: 500984.6584956227,
    ymin: 155156.61517505156,
    xmax: 524680.1372640211,
    ymax: 172297.61876799783,
    zoomLevel: 13,
  },
  {
    id: 3610,
    text: "EPSOM AND EWELL BOROUGH COUNCIL",
    xmin: 509755.8074913442,
    ymin: 152041.80627243122,
    xmax: 533451.2862597427,
    ymax: 169182.8098653775,
    zoomLevel: 13,
  },
  {
    id: 3615,
    text: "GUILDFORD BOROUGH COUNCIL",
    xmin: 478626.82772221515,
    ymin: 132215.95206096943,
    xmax: 526017.785259012,
    ymax: 166497.95924686204,
    zoomLevel: 12,
  },
  {
    id: 3620,
    text: "MOLE VALLEY DISTRICT COUNCIL",
    xmin: 495939.8146288037,
    ymin: 129655.31161341691,
    xmax: 543330.7721656006,
    ymax: 163937.31879930952,
    zoomLevel: 12,
  },
  {
    id: 3625,
    text: "REIGATE AND BANSTEAD BOROUGH COUNCIL",
    xmin: 504309.66922602773,
    ymin: 134088.65925395614,
    xmax: 551700.6267628246,
    ymax: 168370.66643984875,
    zoomLevel: 12,
  },
  {
    id: 3630,
    text: "RUNNYMEDE BOROUGH COUNCIL",
    xmin: 490894.97076198325,
    ymin: 158768.26476152596,
    xmax: 514590.44953038165,
    ymax: 175909.2683544723,
    zoomLevel: 13,
  },
  {
    id: 3635,
    text: "SPELTHORNE BOROUGH COUNCIL",
    xmin: 494296.4185206727,
    ymin: 162074.1662348584,
    xmax: 517991.8972890711,
    ymax: 179215.16982780473,
    zoomLevel: 13,
  },
  {
    id: 3640,
    text: "SURREY HEATH BOROUGH COUNCIL",
    xmin: 479563.1813187082,
    ymin: 152290.2266143585,
    xmax: 503258.6600871066,
    ymax: 169431.23020730482,
    zoomLevel: 13,
  },
  {
    id: 3645,
    text: "TANDRIDGE DISTRICT COUNCIL",
    xmin: 510176.2111469119,
    ymin: 133419.8352564604,
    xmax: 557567.1686837088,
    ymax: 167701.84244235302,
    zoomLevel: 12,
  },
  {
    id: 3650,
    text: "WAVERLEY BOROUGH COUNCIL",
    xmin: 473906.84122560464,
    ymin: 123444.80306524751,
    xmax: 521297.79876240157,
    ymax: 157726.81025114012,
    zoomLevel: 12,
  },
  {
    id: 3655,
    text: "WOKING BOROUGH COUNCIL",
    xmin: 489270.68391092087,
    ymin: 148678.57702788478,
    xmax: 512966.16267931927,
    ymax: 165819.5806208311,
    zoomLevel: 13,
  },
  {
    id: 3700,
    text: "WARWICKSHIRE COUNTY COUNCIL",
    xmin: 333683.112836486,
    ymin: 204410.72527629888,
    xmax: 523246.9429836737,
    ymax: 341538.7540198693,
    zoomLevel: 10,
  },
  {
    id: 3705,
    text: "NORTH WARWICKSHIRE BOROUGH COUNCIL",
    xmin: 403508.3381749764,
    ymin: 280637.55173367064,
    xmax: 450899.29571177333,
    ymax: 314919.5589195633,
    zoomLevel: 12,
  },
  {
    id: 3710,
    text: "NUNEATON AND BEDWORTH BOROUGH COUNCIL",
    xmin: 424280.1006114684,
    ymin: 281182.16556020215,
    xmax: 447975.57937986683,
    ymax: 298323.1691531485,
    zoomLevel: 13,
  },
  {
    id: 3715,
    text: "RUGBY BOROUGH COUNCIL",
    xmin: 422101.89608928986,
    ymin: 259363.27561685102,
    xmax: 469492.8536260868,
    ymax: 293645.2828027436,
    zoomLevel: 12,
  },
  {
    id: 3720,
    text: "STRATFORD-ON-AVON DISTRICT COUNCIL",
    xmin: 382087.11178200995,
    ymin: 217342.0193170865,
    xmax: 476869.0268556038,
    ymax: 285906.0336888717,
    zoomLevel: 11,
  },
  {
    id: 3725,
    text: "WARWICK DISTRICT COUNCIL",
    xmin: 405266.6406094844,
    ymin: 249827.7563382775,
    xmax: 452657.5981462813,
    ymax: 284109.76352417015,
    zoomLevel: 12,
  },
  {
    id: 3800,
    text: "WEST SUSSEX COUNTY COUNCIL",
    xmin: 467524.8870390232,
    ymin: 84739.5170121637,
    xmax: 562306.8021126171,
    ymax: 153303.53138394892,
    zoomLevel: 11,
  },
  {
    id: 3805,
    text: "ADUR DISTRICT COUNCIL",
    xmin: 510826.46356256393,
    ymin: 98947.24964466714,
    xmax: 534521.9423309624,
    ymax: 116088.25323761345,
    zoomLevel: 13,
  },
  {
    id: 3810,
    text: "ARUN DISTRICT COUNCIL",
    xmin: 481321.7706444935,
    ymin: 89516.83127998599,
    xmax: 528712.7281812904,
    ymax: 123798.8384658786,
    zoomLevel: 12,
  },
  {
    id: 3815,
    text: "CHICHESTER DISTRICT COUNCIL",
    xmin: 445014.1822090444,
    ymin: 83803.16341567045,
    xmax: 539796.0972826382,
    ymax: 152367.17778745567,
    zoomLevel: 11,
  },
  {
    id: 3820,
    text: "CRAWLEY BOROUGH COUNCIL",
    xmin: 521871.6141497693,
    ymin: 132823.18511779472,
    xmax: 533719.3535339686,
    ymax: 141393.6869142679,
    zoomLevel: 14,
  },
  {
    id: 3825,
    text: "HORSHAM DISTRICT COUNCIL",
    xmin: 492405.13974584173,
    ymin: 107431.75978431923,
    xmax: 539796.0972826387,
    ymax: 141713.76697021184,
    zoomLevel: 12,
  },
  {
    id: 3830,
    text: "MID SUSSEX DISTRICT COUNCIL",
    xmin: 510558.93396356626,
    ymin: 108501.8781803114,
    xmax: 557949.8915003631,
    ymax: 142783.88536620402,
    zoomLevel: 12,
  },
  {
    id: 3835,
    text: "WORTHING BOROUGH COUNCIL",
    xmin: 507759.42780262243,
    ymin: 99945.70832664179,
    xmax: 519607.1671868217,
    ymax: 108516.21012311496,
    zoomLevel: 14,
  },
  {
    id: 3935,
    text: "SWINDON BOROUGH COUNCIL",
    xmin: 408780.24259354745,
    ymin: 181410.82639988285,
    xmax: 420627.9819777467,
    ymax: 189981.32819635601,
    zoomLevel: 14,
  },
  {
    id: 3940,
    text: "WILTSHIRE COUNCIL",
    xmin: 299781.0402589145,
    ymin: 86748.34418298464,
    xmax: 489344.8704061022,
    ymax: 223876.37292655508,
    zoomLevel: 10,
  },
  {
    id: 4205,
    text: "BOLTON METROPOLITAN BOROUGH COUNCIL",
    xmin: 356620.4540262175,
    ymin: 401276.09845492424,
    xmax: 380315.9327946159,
    ymax: 418417.1020478706,
    zoomLevel: 13,
  },
  {
    id: 4210,
    text: "BURY METROPOLITAN BOROUGH COUNCIL",
    xmin: 368793.0507806288,
    ymin: 404027.83147318946,
    xmax: 392488.5295490272,
    ymax: 421168.8350661358,
    zoomLevel: 13,
  },
  {
    id: 4215,
    text: "MANCHESTER CITY COUNCIL",
    xmin: 372614.9021948869,
    ymin: 388702.2073020156,
    xmax: 396310.3809632853,
    ymax: 405843.21089496196,
    zoomLevel: 13,
  },
  {
    id: 4220,
    text: "OLDHAM METROPOLITAN BOROUGH COUNCIL",
    xmin: 386727.08854203305,
    ymin: 400048.3286880939,
    xmax: 398574.8279262323,
    ymax: 408618.8304845671,
    zoomLevel: 14,
  },
  {
    id: 4225,
    text: "ROCHDALE BOROUGH COUNCIL",
    xmin: 376083.2323533269,
    ymin: 406024.74883713905,
    xmax: 399778.7111217253,
    ymax: 423165.7524300854,
    zoomLevel: 13,
  },
  {
    id: 4230,
    text: "SALFORD CITY COUNCIL",
    xmin: 375567.2824124021,
    ymin: 395261.4597917357,
    xmax: 387415.02179660136,
    ymax: 403831.96158820885,
    zoomLevel: 14,
  },
  {
    id: 4235,
    text: "STOCKPORT METROPOLITAN BOROUGH COUNCIL",
    xmin: 384118.67495180445,
    ymin: 387398.0005069002,
    xmax: 395966.4143360037,
    ymax: 395968.50230337336,
    zoomLevel: 14,
  },
  {
    id: 4240,
    text: "TAMESIDE METROPOLITAN BOROUGH COUNCIL",
    xmin: 383621.83426795097,
    ymin: 388406.01381741,
    xmax: 407317.31303634937,
    ymax: 405547.0174103563,
    zoomLevel: 13,
  },
  {
    id: 4245,
    text: "TRAFFORD METROPOLITAN BOROUGH COUNCIL",
    xmin: 365085.85490880045,
    ymin: 383456.7162359464,
    xmax: 388781.33367719885,
    ymax: 400597.71982889273,
    zoomLevel: 13,
  },
  {
    id: 4250,
    text: "WIGAN METROPOLITAN BOROUGH COUNCIL",
    xmin: 352043.7869576457,
    ymin: 400860.4721136227,
    xmax: 363891.52634184493,
    ymax: 409430.97391009587,
    zoomLevel: 14,
  },
  {
    id: 4305,
    text: "KNOWSLEY METROPOLITAN BOROUGH COUNCIL",
    xmin: 339288.796775217,
    ymin: 390628.5428487056,
    xmax: 351136.53615941625,
    ymax: 399199.0446451788,
    zoomLevel: 14,
  },
  {
    id: 4310,
    text: "LIVERPOOL CITY COUNCIL",
    xmin: 329504.8571547171,
    ymin: 381795.28876750247,
    xmax: 353200.3359231155,
    ymax: 398936.2923604488,
    zoomLevel: 13,
  },
  {
    id: 4315,
    text: "ST. HELENS COUNCIL",
    xmin: 345900.59972188366,
    ymin: 390733.64376259805,
    xmax: 357748.3391060829,
    ymax: 399304.1455590712,
    zoomLevel: 14,
  },
  {
    id: 4320,
    text: "SEFTON METROPOLITAN BOROUGH COUNCIL",
    xmin: 329232.5918569629,
    ymin: 396735.3305130692,
    xmax: 341080.33124116214,
    ymax: 405305.83230954234,
    zoomLevel: 14,
  },
  {
    id: 4325,
    text: "WIRRAL BOROUGH COUNCIL",
    xmin: 317977.2394419732,
    ymin: 377305.99338583613,
    xmax: 341672.7182103716,
    ymax: 394446.99697878247,
    zoomLevel: 13,
  },
  {
    id: 4405,
    text: "BARNSLEY METROPOLITAN BOROUGH COUNCIL",
    xmin: 430394.4202138734,
    ymin: 403221.67393262,
    xmax: 442242.15959807264,
    ymax: 411792.17572909314,
    zoomLevel: 14,
  },
  {
    id: 4410,
    text: "CITY OF DONCASTER COUNCIL",
    xmin: 446350.6498683999,
    ymin: 394216.4365377747,
    xmax: 470046.1286367983,
    ymax: 411357.440130721,
    zoomLevel: 13,
  },
  {
    id: 4415,
    text: "ROTHERHAM METROPOLITAN BOROUGH COUNCIL",
    xmin: 436681.3657903278,
    ymin: 388507.54598772747,
    xmax: 448529.10517452704,
    ymax: 397078.04778420064,
    zoomLevel: 14,
  },
  {
    id: 4420,
    text: "SHEFFIELD CITY COUNCIL",
    xmin: 412211.96211054275,
    ymin: 371801.27799315285,
    xmax: 459602.9196473397,
    ymax: 406083.2851790454,
    zoomLevel: 12,
  },
  {
    id: 4505,
    text: "GATESHEAD METROPOLITAN BOROUGH COUNCIL",
    xmin: 420684.94050079957,
    ymin: 556887.1656893214,
    xmax: 432532.6798849988,
    ymax: 565457.6674857944,
    zoomLevel: 14,
  },
  {
    id: 4510,
    text: "NEWCASTLE CITY COUNCIL",
    xmin: 411378.7323070821,
    ymin: 558917.5242531439,
    xmax: 435074.2110754805,
    ymax: 576058.5278460903,
    zoomLevel: 13,
  },
  {
    id: 4515,
    text: "NORTH TYNESIDE COUNCIL",
    xmin: 418506.4851946738,
    ymin: 560943.1055027007,
    xmax: 442201.9639630722,
    ymax: 578084.109095647,
    zoomLevel: 13,
  },
  {
    id: 4520,
    text: "SOUTH TYNESIDE COUNCIL",
    xmin: 421411.0922695098,
    ymin: 554579.7228979617,
    xmax: 445106.5710379082,
    ymax: 571720.7264909081,
    zoomLevel: 13,
  },
  {
    id: 4525,
    text: "SUNDERLAND CITY COUNCIL",
    xmin: 426844.51663027884,
    ymin: 547172.934539493,
    xmax: 450539.99539867725,
    ymax: 564313.9381324394,
    zoomLevel: 13,
  },
  {
    id: 4605,
    text: "BIRMINGHAM CITY COUNCIL",
    xmin: 383793.9156428557,
    ymin: 270739.6366471703,
    xmax: 431184.8731796526,
    ymax: 305021.64383306296,
    zoomLevel: 12,
  },
  {
    id: 4610,
    text: "COVENTRY CITY COUNCIL",
    xmin: 421687.5724152221,
    ymin: 271341.57824491564,
    xmax: 445383.0511836205,
    ymax: 288482.581837862,
    zoomLevel: 13,
  },
  {
    id: 4615,
    text: "DUDLEY METROPOLITAN BOROUGH COUNCIL",
    xmin: 381825.6621645142,
    ymin: 281545.92152098357,
    xmax: 405521.1409329126,
    ymax: 298686.9251139299,
    zoomLevel: 13,
  },
  {
    id: 4620,
    text: "SANDWELL METROPOLITAN BOROUGH COUNCIL",
    xmin: 387615.7670571147,
    ymin: 282157.41774726444,
    xmax: 411311.2458255131,
    ymax: 299298.42134021077,
    zoomLevel: 13,
  },
  {
    id: 4625,
    text: "SOLIHULL METROPOLITAN BOROUGH COUNCIL",
    xmin: 409161.45440499374,
    ymin: 276649.1743964646,
    xmax: 421009.193789193,
    ymax: 285219.67619293777,
    zoomLevel: 14,
  },
  {
    id: 4630,
    text: "WALSALL METROPOLITAN BOROUGH COUNCIL",
    xmin: 390291.06304709596,
    ymin: 291253.42411319725,
    xmax: 413986.54181549436,
    ymax: 308394.4277061436,
    zoomLevel: 13,
  },
  {
    id: 4635,
    text: "CITY OF WOLVERHAMPTON COUNCIL",
    xmin: 381080.4011387354,
    ymin: 290852.12971470004,
    xmax: 404775.8799071338,
    ymax: 307993.1333076464,
    zoomLevel: 13,
  },
  {
    id: 4705,
    text: "CITY OF BRADFORD METROPOLITAN DISTRICT COUNCIL",
    xmin: 405253.6113339164,
    ymin: 424827.13104150794,
    xmax: 428949.0901023148,
    ymax: 441968.13463445427,
    zoomLevel: 13,
  },
  {
    id: 4710,
    text: "CALDERDALE METROPOLITAN BOROUGH COUNCIL",
    xmin: 382265.17507715593,
    ymin: 408116.0857326664,
    xmax: 429656.13261395285,
    ymax: 442398.092918559,
    zoomLevel: 12,
  },
  {
    id: 4715,
    text: "KIRKLEES COUNCIL",
    xmin: 388647.6669389661,
    ymin: 400931.00507386145,
    xmax: 436038.624475763,
    ymax: 435213.0122597541,
    zoomLevel: 12,
  },
  {
    id: 4720,
    text: "LEEDS CITY COUNCIL",
    xmin: 417961.2672863237,
    ymin: 425648.82909557386,
    xmax: 441656.7460547221,
    ymax: 442789.8326885202,
    zoomLevel: 13,
  },
  {
    id: 4725,
    text: "CITY OF WAKEFIELD METROPOLITAN DISTRICT COUNCIL",
    xmin: 420942.31138944486,
    ymin: 411183.1214926084,
    xmax: 444637.79015784326,
    ymax: 428324.1250855547,
    zoomLevel: 13,
  },
  {
    id: 5030,
    text: "CITY OF LONDON CORPORATION",
    xmin: 530805.7195319165,
    ymin: 180192.739033886,
    xmax: 533767.6543779664,
    ymax: 182335.36448300426,
    zoomLevel: 16,
  },
  {
    id: 5060,
    text: "LONDON BOROUGH OF BARKING AND DAGENHAM",
    xmin: 536447.7276822124,
    ymin: 177867.3813140231,
    xmax: 560143.2064506109,
    ymax: 195008.38490696944,
    zoomLevel: 13,
  },
  {
    id: 5090,
    text: "LONDON BOROUGH OF BARNET",
    xmin: 511540.6488479345,
    ymin: 183854.55614504096,
    xmax: 535236.127616333,
    ymax: 200995.55973798723,
    zoomLevel: 13,
  },
  {
    id: 5120,
    text: "LONDON BOROUGH OF BEXLEY",
    xmin: 537491.0199507447,
    ymin: 167401.48580666108,
    xmax: 561186.4987191432,
    ymax: 184542.4893996074,
    zoomLevel: 13,
  },
  {
    id: 5150,
    text: "LONDON BOROUGH OF BRENT",
    xmin: 508674.26028724096,
    ymin: 177185.42542716122,
    xmax: 532369.7390556394,
    ymax: 194326.42902010755,
    zoomLevel: 13,
  },
  {
    id: 5180,
    text: "LONDON BOROUGH OF BROMLEY",
    xmin: 517999.57773802994,
    ymin: 149142.5906750443,
    xmax: 565390.5352748268,
    ymax: 183424.59786093692,
    zoomLevel: 12,
  },
  {
    id: 5210,
    text: "LONDON BOROUGH OF CAMDEN",
    xmin: 522241.83280785684,
    ymin: 179636.18764655403,
    xmax: 534089.5721920561,
    ymax: 188206.6894430272,
    zoomLevel: 14,
  },
  {
    id: 5240,
    text: "LONDON BOROUGH OF CROYDON",
    xmin: 521446.3786577039,
    ymin: 154388.92747659562,
    xmax: 545141.8574261024,
    ymax: 171529.9310695419,
    zoomLevel: 13,
  },
  {
    id: 5270,
    text: "LONDON BOROUGH OF EALING",
    xmin: 509417.1013313288,
    ymin: 178156.0659590109,
    xmax: 521264.84071552806,
    ymax: 186726.56775548408,
    zoomLevel: 14,
  },
  {
    id: 5300,
    text: "LONDON BOROUGH OF ENFIELD",
    xmin: 520348.50578710315,
    ymin: 187608.76939950627,
    xmax: 544043.9845555016,
    ymax: 204749.7729924526,
    zoomLevel: 13,
  },
  {
    id: 5330,
    text: "LONDON BOROUGH OF GREENWICH",
    xmin: 530314.2678944876,
    ymin: 168054.09540470474,
    xmax: 554009.7466628861,
    ymax: 185195.09899765108,
    zoomLevel: 13,
  },
  {
    id: 5360,
    text: "LONDON BOROUGH OF HACKNEY",
    xmin: 529253.704127032,
    ymin: 180594.54535773778,
    xmax: 541101.4435112312,
    ymax: 189165.04715421094,
    zoomLevel: 14,
  },
  {
    id: 5390,
    text: "LONDON BOROUGH OF HAMMERSMITH AND FULHAM",
    xmin: 518428.30999614834,
    ymin: 174823.5497222083,
    xmax: 530276.0493803476,
    ymax: 183394.05151868146,
    zoomLevel: 14,
  },
  {
    id: 5420,
    text: "LONDON BOROUGH OF HARINGEY",
    xmin: 525298.0879132763,
    ymin: 184645.7078568508,
    xmax: 537145.8272974755,
    ymax: 193216.20965332395,
    zoomLevel: 14,
  },
  {
    id: 5450,
    text: "LONDON BOROUGH OF HARROW",
    xmin: 504019.9301643977,
    ymin: 181554.78552556993,
    xmax: 527715.4089327961,
    ymax: 198695.78911851626,
    zoomLevel: 13,
  },
  {
    id: 5480,
    text: "LONDON BOROUGH OF HAVERING",
    xmin: 542804.1702146519,
    ymin: 178051.03760447213,
    xmax: 566499.6489830504,
    ymax: 195192.04119741847,
    zoomLevel: 13,
  },
  {
    id: 5510,
    text: "LONDON BOROUGH OF HILLINGDON",
    xmin: 485055.9953452159,
    ymin: 167110.98793115912,
    xmax: 532446.9528820128,
    ymax: 201392.99511705173,
    zoomLevel: 12,
  },
  {
    id: 5540,
    text: "LONDON BOROUGH OF HOUNSLOW",
    xmin: 502349.8729947325,
    ymin: 166298.8445056292,
    xmax: 526045.351763131,
    ymax: 183439.84809857555,
    zoomLevel: 13,
  },
  {
    id: 5570,
    text: "LONDON BOROUGH OF ISLINGTON",
    xmin: 525567.6203363495,
    ymin: 180119.61468243875,
    xmax: 537415.3597205487,
    ymax: 188690.11647891192,
    zoomLevel: 14,
  },
  {
    id: 5600,
    text: "LONDON BOROUGH OF KENSINGTON AND CHELSEA",
    xmin: 520207.4737278535,
    ymin: 175523.8383567935,
    xmax: 532055.2131120528,
    ymax: 184094.34015326668,
    zoomLevel: 14,
  },
  {
    id: 5630,
    text: "LONDON BOROUGH OF KINGSTON UPON THAMES",
    xmin: 508512.6084002253,
    ymin: 156954.41779776866,
    xmax: 532208.0871686237,
    ymax: 174095.421390715,
    zoomLevel: 13,
  },
  {
    id: 5660,
    text: "LONDON BOROUGH OF LAMBETH",
    xmin: 518029.0184217266,
    ymin: 166719.24816119723,
    xmax: 541724.497190125,
    ymax: 183860.25175414357,
    zoomLevel: 13,
  },
  {
    id: 5690,
    text: "LONDON BOROUGH OF LEWISHAM",
    xmin: 526580.4109611278,
    ymin: 165668.23902227642,
    xmax: 550275.8897295264,
    ymax: 182809.24261522276,
    zoomLevel: 13,
  },
  {
    id: 5720,
    text: "LONDON BOROUGH OF MERTON",
    xmin: 519825.2885864265,
    ymin: 165080.62936733448,
    xmax: 531673.0279706258,
    ymax: 173651.13116380764,
    zoomLevel: 14,
  },
  {
    id: 5750,
    text: "LONDON BOROUGH OF NEWHAM",
    xmin: 535915.2830404511,
    ymin: 179097.26942912527,
    xmax: 547763.0224246504,
    ymax: 187667.77122559844,
    zoomLevel: 14,
  },
  {
    id: 5780,
    text: "LONDON BOROUGH OF REDBRIDGE",
    xmin: 537826.2087475791,
    ymin: 185756.84551846897,
    xmax: 549673.9481317784,
    ymax: 194327.34731494213,
    zoomLevel: 14,
  },
  {
    id: 5810,
    text: "LONDON BOROUGH OF RICHMOND UPON THAMES",
    xmin: 505125.8736435458,
    ymin: 164503.63596894144,
    xmax: 528821.3524119442,
    ymax: 181644.63956188777,
    zoomLevel: 13,
  },
  {
    id: 5840,
    text: "LONDON BOROUGH OF SOUTHWARK",
    xmin: 522343.314264778,
    ymin: 167809.5374422744,
    xmax: 546038.7930331765,
    ymax: 184950.54103522067,
    zoomLevel: 13,
  },
  {
    id: 5870,
    text: "LONDON BOROUGH OF SUTTON",
    xmin: 520346.4715463652,
    ymin: 161061.58103897577,
    xmax: 532194.2109305644,
    ymax: 169632.08283544893,
    zoomLevel: 14,
  },
  {
    id: 5900,
    text: "LONDON BOROUGH OF TOWER HAMLETS",
    xmin: 530636.8064792543,
    ymin: 177161.13012153702,
    xmax: 542484.5458634535,
    ymax: 185731.6319180102,
    zoomLevel: 14,
  },
  {
    id: 5930,
    text: "LONDON BOROUGH OF WALTHAM FOREST",
    xmin: 526757.6272937827,
    ymin: 181073.75050688325,
    xmax: 550453.1060621812,
    ymax: 198214.75409982953,
    zoomLevel: 13,
  },
  {
    id: 5960,
    text: "LONDON BOROUGH OF WANDSWORTH",
    xmin: 520489.7909744006,
    ymin: 169546.09117862835,
    xmax: 532337.5303585998,
    ymax: 178116.59297510152,
    zoomLevel: 14,
  },
  {
    id: 5990,
    text: "CITY OF WESTMINSTER",
    xmin: 522706.46479467046,
    ymin: 176501.86075257757,
    xmax: 534554.2041788697,
    ymax: 185072.36254905074,
    zoomLevel: 14,
  },
  {
    id: 6805,
    text: "ISLE OF ANGLESEY COUNTY COUNCIL",
    xmin: 218662.54988718376,
    ymin: 360858.8041450598,
    xmax: 266053.5074239807,
    ymax: 395140.81133095245,
    zoomLevel: 12,
  },
  {
    id: 6810,
    text: "GWYNEDD COUNCIL",
    xmin: 165003.7560310042,
    ymin: 271370.15328021336,
    xmax: 354567.5861781919,
    ymax: 408498.1820237838,
    zoomLevel: 10,
  },
  {
    id: 6815,
    text: "CITY OF CARDIFF COUNCIL",
    xmin: 305835.8945886459,
    ymin: 169670.1410822196,
    xmax: 329531.3733570443,
    ymax: 186811.14467516594,
    zoomLevel: 13,
  },
  {
    id: 6820,
    text: "CEREDIGION COUNTY COUNCIL",
    xmin: 206509.06238984398,
    ymin: 234183.53901948448,
    xmax: 301290.9774634378,
    ymax: 302747.5533912697,
    zoomLevel: 11,
  },
  {
    id: 6825,
    text: "CARMARTHENSHIRE COUNTY COUNCIL",
    xmin: 197413.05602391047,
    ymin: 190843.7439818008,
    xmax: 292194.9710975043,
    ymax: 259407.75835358602,
    zoomLevel: 11,
  },
  {
    id: 6830,
    text: "DENBIGHSHIRE COUNTY COUNCIL",
    xmin: 259905.93490381353,
    ymin: 324390.43590804737,
    xmax: 354687.8499774074,
    ymax: 392954.4502798326,
    zoomLevel: 11,
  },
  {
    id: 6835,
    text: "FLINTSHIRE COUNTY COUNCIL",
    xmin: 297589.3898483956,
    ymin: 351506.4716922068,
    xmax: 344980.3473851925,
    ymax: 385788.4788780995,
    zoomLevel: 12,
  },
  {
    id: 6840,
    text: "MONMOUTHSHIRE COUNTY COUNCIL",
    xmin: 295572.0865087001,
    ymin: 173797.40321109342,
    xmax: 390354.00158229395,
    ymax: 242361.41758287864,
    zoomLevel: 11,
  },
  {
    id: 6845,
    text: "PEMBROKESHIRE COUNTY COUNCIL",
    xmin: 159624.02910630679,
    ymin: 187538.74950595887,
    xmax: 254405.94417990063,
    ymax: 256102.7638777441,
    zoomLevel: 11,
  },
  {
    id: 6850,
    text: "POWYS COUNTY COUNCIL",
    xmin: 206250.6163602519,
    ymin: 197895.96683859738,
    xmax: 395814.4465074396,
    ymax: 335023.9955821678,
    zoomLevel: 10,
  },
  {
    id: 6855,
    text: "CITY AND COUNTY OF SWANSEA COUNCIL",
    xmin: 235029.1575096135,
    ymin: 180869.6187880787,
    xmax: 282420.1150464104,
    ymax: 215151.6259739713,
    zoomLevel: 12,
  },
  {
    id: 6905,
    text: "CONWY COUNTY BOROUGH COUNCIL",
    xmin: 239401.5646375895,
    ymin: 325459.47257995605,
    xmax: 334183.47971118335,
    ymax: 394023.4869517413,
    zoomLevel: 11,
  },
  {
    id: 6910,
    text: "BLAENAU GWENT COUNTY BOROUGH COUNCIL",
    xmin: 293551.747777653,
    ymin: 188652.51246405492,
    xmax: 340942.7053144499,
    ymax: 222934.51964994753,
    zoomLevel: 12,
  },
  {
    id: 6915,
    text: "BRIDGEND COUNTY BOROUGH COUNCIL",
    xmin: 265156.3893683611,
    ymin: 168324.3653334992,
    xmax: 312547.346905158,
    ymax: 202606.37251939182,
    zoomLevel: 12,
  },
  {
    id: 6920,
    text: "CAERPHILLY COUNTY BOROUGH COUNCIL",
    xmin: 293782.0564611523,
    ymin: 179101.98632170612,
    xmax: 341173.0139979492,
    ymax: 213383.99350759873,
    zoomLevel: 12,
  },
  {
    id: 6925,
    text: "MERTHYR TYDFIL COUNTY BOROUGH COUNCIL",
    xmin: 282240.0651900944,
    ymin: 188541.95931492298,
    xmax: 329631.02272689133,
    ymax: 222823.9665008156,
    zoomLevel: 12,
  },
  {
    id: 6930,
    text: "NEATH PORT TALBOT COUNTY BOROUGH COUNCIL",
    xmin: 260187.98252982768,
    ymin: 181127.56757126297,
    xmax: 307578.9400666246,
    ymax: 215409.57475715558,
    zoomLevel: 12,
  },
  {
    id: 6935,
    text: "NEWPORT CITY COUNCIL",
    xmin: 319254.6961371826,
    ymin: 178251.62438203383,
    xmax: 342950.174905581,
    ymax: 195392.6279749801,
    zoomLevel: 13,
  },
  {
    id: 6940,
    text: "RHONDDA CYNON TAF COUNTY BOROUGH COUNCIL",
    xmin: 255238.68494836456,
    ymin: 164693.6064899544,
    xmax: 350020.6000219584,
    ymax: 233257.62086173962,
    zoomLevel: 11,
  },
  {
    id: 6945,
    text: "TORFAEN COUNTY BOROUGH COUNCIL",
    xmin: 304464.13116400526,
    ymin: 184739.2171577368,
    xmax: 351855.0887008022,
    ymax: 219021.2243436294,
    zoomLevel: 12,
  },
  {
    id: 6950,
    text: "VALE OF GLAMORGAN COUNCIL",
    xmin: 281036.18199460476,
    ymin: 156189.98709323077,
    xmax: 328427.1395314017,
    ymax: 190471.99427912338,
    zoomLevel: 12,
  },
  {
    id: 6955,
    text: "WREXHAM COUNTY BOROUGH COUNCIL",
    xmin: 283832.35026982444,
    ymin: 312442.80600705557,
    xmax: 378614.2653434183,
    ymax: 381006.8203788408,
    zoomLevel: 11,
  },
  {
    id: 7000,
    text: "AVON FIRE & RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7001,
    text: "BEDFORDSHIRE & LUTON FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7002,
    text: "BUCKINGHAMSHIRE AND MILTON KEYNES FIRE & RESCUE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7003,
    text: "CAMBRIDGESHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7004,
    text: "CENTRAL SCOTLAND FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7005,
    text: "CHESHIRE FIRE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7006,
    text: "CLEVELAND FIRE BRGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7007,
    text: "CO DURHAM AND DARLINGTON FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7008,
    text: "DERBYSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7009,
    text: "DEVON FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7010,
    text: "DORSET FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7011,
    text: "DUMFRIES AND GALLOWAY FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7012,
    text: "EAST SUSSEX FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7013,
    text: "ESSEX COUNTY FIRE & RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7014,
    text: "FIFE FIRE & RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7015,
    text: "GRAMPIAN FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7016,
    text: "GREATER MANCHESTER FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7017,
    text: "HAMPSHIRE FIRE & RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7018,
    text: "HEREFORD AND WORCESTER FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7019,
    text: "HIGHLANDS & ISLANDS FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7020,
    text: "HUMBERSIDE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7021,
    text: "KENT FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7022,
    text: "LANCASHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7023,
    text: "LEICESTERSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7024,
    text: "LONDON FIRE AND EMERGENCY PLANNING AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7025,
    text: "LOTHIAN AND BORDERS FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7026,
    text: "MERSEYSIDE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7027,
    text: "MID & WEST WALES FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7028,
    text: "NORTH WALES FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7029,
    text: "NORTH YORKSHIRE FIRE AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7030,
    text: "NOTTINGHAMSHIRE FIRE AND RESCUE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7031,
    text: "ROYAL BERKSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7032,
    text: "SHROPSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7033,
    text: "SOUTH WALES FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7034,
    text: "SOUTH YORKSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7035,
    text: "STAFFORDSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7036,
    text: "STRATHCLYDE FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7037,
    text: "TAYSIDE FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7038,
    text: "TYNE AND WEAR FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7039,
    text: "WEST MIDLANDS FIRE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7040,
    text: "WEST YORKSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7041,
    text: "WILTSHIRE FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7100,
    text: "AVON AND SOMERSET CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7101,
    text: "BEDFORDSHIRE POLICE AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7102,
    text: "BRITISH TRANSPORT POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7103,
    text: "CAMBRIDGESHIRE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7104,
    text: "CENTRAL SCOTLAND POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7105,
    text: "CHESHIRE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7106,
    text: "CITY OF LONDON POLICE AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7107,
    text: "CLEVELAND POLICE AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7108,
    text: "CUMBRIA CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7109,
    text: "DERBYSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7110,
    text: "DEVON AND CORNWALL CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7111,
    text: "DORSET POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7112,
    text: "DUMFRIES AND GALLOWAY CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7113,
    text: "DURHAM CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7114,
    text: "DYFED-POWYS POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7115,
    text: "ESSEX POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7116,
    text: "FIFE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7117,
    text: "GLOUCESTERSHIRE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7118,
    text: "GRAMPIAN POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7119,
    text: "GREATER MANCHESTER POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7120,
    text: "GWENT POLICE / HEDDLU GWENT",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7121,
    text: "HAMPSHIRE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7122,
    text: "HERTFORDSHIRE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7123,
    text: "HUMBERSIDE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7124,
    text: "KENT POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7125,
    text: "LANCASHIRE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7126,
    text: "LEICESTERSHIRE CONSTABULARY FORCE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7127,
    text: "LINCOLNSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7128,
    text: "LOTHIAN AND BORDERS POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7129,
    text: "MERSEYSIDE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7130,
    text: "METROPOLITAN POLICE AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7131,
    text: "NORFOLK CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7132,
    text: "NORTH WALES POLICE / HEDDLU GOGLEDD CYMRU",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7133,
    text: "NORTH YORKSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7134,
    text: "NORTHAMPTONSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7135,
    text: "NORTHERN CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7136,
    text: "NORTHUMBRIA POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7137,
    text: "NOTTINGHAMSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7138,
    text: "SCOTTISH DRUG ENFORCEMENT AGENCY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7139,
    text: "SOUTH WALES POLICE AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7140,
    text: "SOUTH YORKSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7141,
    text: "STAFFORDSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7142,
    text: "STRATHCLYDE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7143,
    text: "SUFFOLK CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7144,
    text: "SURREY POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7145,
    text: "SUSSEX POLICE AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7146,
    text: "TAYSIDE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7147,
    text: "THAMES VALLEY POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7148,
    text: "WARWICKSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7149,
    text: "WEST MERCIA CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7150,
    text: "WEST MIDLANDS POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7151,
    text: "WEST YORKSHIRE POLICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7152,
    text: "WILTSHIRE CONSTABULARY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7200,
    text: "BRECON BEACONS NATIONAL PARK AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7201,
    text: "BROADS AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7202,
    text: "DARTMOOR NATIONAL PARK",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7203,
    text: "EXMOOR NATIONAL PARK",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7204,
    text: "LAKE DISTRICT NATIONAL PARK AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7205,
    text: "NORTH YORKSHIRE MOORS NATIONAL PARK",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7206,
    text: "NORTHUMBERLAND NATIONAL PARK AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7207,
    text: "PEAK DISTRICT NATIONAL PARK AUTHORITY, THE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7208,
    text: "PEMBROKESHIRE COAST NATIONAL PARK",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7209,
    text: "SNOWDONIA NATIONAL PARK",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7210,
    text: "YORKSHIRE DALES NATIONAL PARK AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7211,
    text: "NEW FOREST NATIONAL PARK",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7300,
    text: "CHILTERN CONSERVATION BOARD",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7301,
    text: "COTSWOLD CONSERVATION BOARD",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7302,
    text: "LEE VALLEY REGIONAL PARK AUTHORITY",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7400,
    text: "CORNWALL COUNTY FIRE BRIGADE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7401,
    text: "CUMBRIA FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7402,
    text: "GLOUCESTERSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7403,
    text: "HERTFORDSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7404,
    text: "ISLE OF WIGHT FIRE AND RESCUE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7405,
    text: "LINCOLNSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7406,
    text: "NORFOLK FIRE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7407,
    text: "NORTHAMPTONSHIRE FIRE AND RESCUE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7408,
    text: "NORTHUMBERLAND COUNTY FIRE AND RESCUE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7409,
    text: "OXFORDSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7410,
    text: "SOMERSET FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7411,
    text: "SUFFOLK COUNTY FIRE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7412,
    text: "SURREY FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7413,
    text: "WARWICKSHIRE FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7414,
    text: "WEST SUSSEX FIRE AND RESCUE SERVICE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7415,
    text: "WEST MIDLANDS REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7416,
    text: "SOUTH WEST REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7417,
    text: "SOUTH EAST REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7418,
    text: "YORKSHIRE AND HUMBERSIDE REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7419,
    text: "NORTH WEST REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7420,
    text: "NORTH EAST REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7421,
    text: "EAST OF ENGLAND REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7422,
    text: "EAST MIDLANDS REGIONAL CONTROL CENTRE",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 7423,
    text: "LONDON CONTROL ROOM",
    xmin: undefined,
    ymin: undefined,
    xmax: undefined,
    ymax: undefined,
  },
  {
    id: 9000,
    text: "ORKNEY ISLANDS COUNCIL",
    xmin: 295273.08270697115,
    ymin: 983612.1567495873,
    xmax: 390054.997780565,
    ymax: 1052176.1711213726,
    zoomLevel: 11,
  },
  {
    id: 9010,
    text: "SHETLAND ISLANDS COUNCIL",
    xmin: 350460.61712885473,
    ymin: 1094101.88113578,
    xmax: 540024.4472760424,
    ymax: 1231229.9098793506,
    zoomLevel: 10,
  },
  {
    id: 9020,
    text: "COMHAIRLE NAN EILEAN SIAR (WESTERN ISLES COUNCIL)",
    xmin: -92239.92375284851,
    ymin: 757280.1065319342,
    xmax: 286887.7365415268,
    ymax: 1031536.1640190751,
    zoomLevel: 9,
  },
  {
    id: 9051,
    text: "ABERDEEN CITY COUNCIL",
    xmin: 380140.9110494173,
    ymin: 797782.176894531,
    xmax: 403836.3898178157,
    ymax: 814923.1804874773,
    zoomLevel: 13,
  },
  {
    id: 9052,
    text: "ABERDEENSHIRE COUNCIL",
    xmin: 257000.8584820311,
    ymin: 738705.9086586407,
    xmax: 446564.6886292188,
    ymax: 875833.9374022111,
    zoomLevel: 10,
  },
  {
    id: 9053,
    text: "ANGUS COUNCIL",
    xmin: 300493.52757628553,
    ymin: 728272.2542977184,
    xmax: 395275.4426498794,
    ymax: 796836.2686695035,
    zoomLevel: 11,
  },
  {
    id: 9054,
    text: "ARGYLL & BUTE COUNCIL",
    xmin: -13716.63997988461,
    ymin: 556456.3158575627,
    xmax: 365411.0203144907,
    ymax: 830712.3733447036,
    zoomLevel: 9,
  },
  {
    id: 9055,
    text: "SCOTTISH BORDERS COUNCIL",
    xmin: 255647.4476970054,
    ymin: 563717.8335446505,
    xmax: 445211.2778441931,
    ymax: 700845.8622882209,
    zoomLevel: 10,
  },
  {
    id: 9056,
    text: "CLACKMANNANSHIRE COUNCIL",
    xmin: 267342.31302463374,
    ymin: 679959.4443093017,
    xmax: 314733.27056143066,
    ymax: 714241.4514951943,
    zoomLevel: 12,
  },
  {
    id: 9057,
    text: "WEST DUNBARTONSHIRE COUNCIL",
    xmin: 218116.86680899284,
    ymin: 663525.4832279925,
    xmax: 265507.82434578973,
    ymax: 697807.4904138852,
    zoomLevel: 12,
  },
  {
    id: 9058,
    text: "DUMFRIES & GALLOWAY COUNCIL",
    xmin: 181560.19449348014,
    ymin: 512311.5895365806,
    xmax: 371124.02464066783,
    ymax: 649439.618280151,
    zoomLevel: 10,
  },
  {
    id: 9059,
    text: "DUNDEE CITY COUNCIL",
    xmin: 328816.12948483333,
    ymin: 723287.3422321468,
    xmax: 352511.60825323174,
    ymax: 740428.3458250931,
    zoomLevel: 13,
  },
  {
    id: 9060,
    text: "EAST AYRSHIRE COUNCIL",
    xmin: 203612.27715374666,
    ymin: 586589.2717726841,
    xmax: 298394.1922273405,
    ymax: 655153.2861444692,
    zoomLevel: 11,
  },
  {
    id: 9061,
    text: "EAST DUNBARTONSHIRE COUNCIL",
    xmin: 240110.9581599087,
    ymin: 657733.0358490921,
    xmax: 287501.91569670563,
    ymax: 692015.0430349847,
    zoomLevel: 12,
  },
  {
    id: 9062,
    text: "EAST LOTHIAN COUNCIL",
    xmin: 331756.34786775464,
    ymin: 654999.6327283868,
    xmax: 379147.30540455156,
    ymax: 689281.6399142795,
    zoomLevel: 12,
  },
  {
    id: 9063,
    text: "EAST RENFREWSHIRE COUNCIL",
    xmin: 239867.2965896574,
    ymin: 644944.0435629213,
    xmax: 263562.77535805583,
    ymax: 662085.0471558677,
    zoomLevel: 13,
  },
  {
    id: 9064,
    text: "CITY OF EDINBURGH COUNCIL",
    xmin: 299374.24712430674,
    ymin: 654657.8688784208,
    xmax: 346765.20466110366,
    ymax: 688939.8760643135,
    zoomLevel: 12,
  },
  {
    id: 9065,
    text: "FALKIRK COUNCIL",
    xmin: 265283.33250912797,
    ymin: 664059.6233574945,
    xmax: 312674.2900459249,
    ymax: 698341.6305433871,
    zoomLevel: 12,
  },
  {
    id: 9066,
    text: "FIFE COUNCIL",
    xmin: 271474.7318002255,
    ymin: 669735.0727076672,
    xmax: 366256.64687381935,
    ymax: 738299.0870794526,
    zoomLevel: 11,
  },
  {
    id: 9067,
    text: "GLASGOW COUNCIL",
    xmin: 236466.57284562424,
    ymin: 645179.6773710607,
    xmax: 283857.53038242116,
    ymax: 679461.6845569534,
    zoomLevel: 12,
  },
  {
    id: 9068,
    text: "HIGHLAND COUNCIL",
    xmin: 60814.28184633452,
    ymin: 713839.2380282023,
    xmax: 439941.94214070984,
    ymax: 988095.2955153432,
    zoomLevel: 9,
  },
  {
    id: 9069,
    text: "INVERCLYDE COUNCIL",
    xmin: 213841.2124732173,
    ymin: 662979.9503329657,
    xmax: 237536.69124161577,
    ymax: 680120.953925912,
    zoomLevel: 13,
  },
  {
    id: 9070,
    text: "MIDLOTHIAN COUNCIL",
    xmin: 306885.2971518176,
    ymin: 642202.6442501165,
    xmax: 354276.2546886145,
    ymax: 676484.6514360092,
    zoomLevel: 12,
  },
  {
    id: 9071,
    text: "MORAY COUNCIL",
    xmin: 242412.1460515943,
    ymin: 763370.8264028834,
    xmax: 431975.976198782,
    ymax: 900498.8551464538,
    zoomLevel: 10,
  },
  {
    id: 9072,
    text: "NORTH AYRSHIRE COUNCIL",
    xmin: 178921.9145684501,
    ymin: 612815.5970030362,
    xmax: 273703.8296420439,
    ymax: 681379.6113748215,
    zoomLevel: 11,
  },
  {
    id: 9073,
    text: "NORTH LANARKSHIRE COUNCIL",
    xmin: 257537.39815973362,
    ymin: 649639.1353794116,
    xmax: 304928.35569653055,
    ymax: 683921.1425653043,
    zoomLevel: 12,
  },
  {
    id: 9074,
    text: "PERTH & KINROSS COUNCIL",
    xmin: 200477.15654486447,
    ymin: 662346.7913318184,
    xmax: 390040.98669205216,
    ymax: 799474.8200753889,
    zoomLevel: 10,
  },
  {
    id: 9075,
    text: "RENFREWSHIRE COUNCIL",
    xmin: 215993.87328675084,
    ymin: 645855.5024792963,
    xmax: 263384.83082354773,
    ymax: 680137.509665189,
    zoomLevel: 12,
  },
  {
    id: 9076,
    text: "SOUTH AYRSHIRE COUNCIL",
    xmin: 187597.51727881545,
    ymin: 570240.1722482056,
    xmax: 282379.4323524093,
    ymax: 638804.186619991,
    zoomLevel: 11,
  },
  {
    id: 9077,
    text: "SOUTH LANARKSHIRE COUNCIL",
    xmin: 240109.755710718,
    ymin: 597833.9394591472,
    xmax: 334891.67078431183,
    ymax: 666397.9538309323,
    zoomLevel: 11,
  },
  {
    id: 9078,
    text: "STIRLING COUNCIL",
    xmin: 211904.49227349568,
    ymin: 672283.6050088888,
    xmax: 306686.4073470895,
    ymax: 740847.6193806741,
    zoomLevel: 11,
  },
  {
    id: 9079,
    text: "WEST LOTHIAN COUNCIL",
    xmin: 278786.8920230077,
    ymin: 651779.3721713966,
    xmax: 326177.8495598046,
    ymax: 686061.3793572893,
    zoomLevel: 12,
  },
];

export default DETRCodes;
